<template>
  <div>
    <div>
      <header-geral />
    </div>
    <div class="margintop-menu">
      <div class="container">
        <div class="historico text-left">
          <router-link class="font-weight-bold mr-3 text-dark" to="/"
            >Home</router-link
          >
          <router-link
            class="font-weight-bold appColor-primary"
            :to="`/detalhes-produto/${produtoId}`"
          >
            <span class="mr-3">></span>
            <span>Produto</span>
          </router-link>
        </div>
        <div class="row sessao-produto mt-8">
          <div class="col-lg-4">
            <v-img
              :src="produto.src"
              class="text-center"
              aspect-ratio="2"
              contain
            ></v-img>
          </div>
          <div class="col-lg-8">
            <h1 class="font-weight-bold text-dark mt-0 text-left">
              {{ produto.titulo }}
            </h1>
            <p class="text-left">{{ produto.descricao }}</p>
            <div class="d-flex align-items-end mt-3">
              <h4 class="appColor-primary font-weight-bold mr-2">R$</h4>
              <h2 class="appColor-primary font-weight-bold">
                {{
                  parseFloat(produto.valorTotal)
                    | money({
                      prefix: "",
                      suffix: "",
                      thousands: ".",
                      decimal: ",",
                      precision: 2,
                    })
                }}
              </h2>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <div
                  class="
                    contador
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <v-icon
                    class="app-Border-primary appColor-primary"
                    @click="quantidade--"
                    :disabled="quantidade <= 1 ? true : false"
                  >
                    mdi-minus
                  </v-icon>
                  <h3 class="font-weight-light">{{ quantidade }}</h3>
                  <v-icon
                    class="app-Border-primary appColor-primary"
                    @click="quantidade++"
                  >
                    mdi-plus
                  </v-icon>
                </div>
              </div>
              <div class="col-lg-9 text-left">
                <v-btn
                  class="appBG-primary appColor-white mt-2"
                  @click="adicionaraoCarrinho"
                >
                  <v-icon left> mdi-basket </v-icon>
                  Adicionar ao carrinho
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="row informacoes-adcionais mt-8">
          <div class="col-12">
            <div class="d-flex align-items">
              <v-icon class="appColor-primary mr-3">mdi-information</v-icon>
              <h3 class="appColor-primary font-weight-bold">
                Mais informações
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-6" v-if="produto.prevencao.length>= 1">
              <h4 class="text-left font-weight-bold">O que previne:</h4>
              <ul>
                <li
                  class="text-left"
                  v-for="(previne, i) in produto.prevencao"
                  :key="i"
                >
                  {{ previne }}
                </li>
              </ul>
            </div>
            <div class="col-6" v-if="produto.indicacoes.length>= 1">
              <h4 class="text-left font-weight-bold">Indicação:</h4>
              <ul>
                <li
                  class="text-left"
                  v-for="(indicacao, i) in produto.indicacoes"
                  :key="i"
                >
                  {{ indicacao }}
                </li>
              </ul>
            </div>
            <div class="col-6" v-if="produto.recomendacoes.length>= 1">
              <h4 class="text-left font-weight-bold">Outras recomendações</h4>
              <ul>
                <li
                  class="text-left"
                  v-for="(recomendacao, i) in produto.recomendacoes"
                  :key="i"
                >
                  {{ recomendacao }}
                </li>
              </ul>
            </div>
            <div class="col-6" v-if="produto.contraindicacoes.length>= 1">
              <h4 class="text-left font-weight-bold">Contraindicação:</h4>
              <ul>
                <li
                  class="text-left"
                  v-for="(contraindicacao, i) in produto.contraindicacoes"
                  :key="i"
                >
                  {{ contraindicacao }}
                </li>
              </ul>
            </div>
            <div class="col-12" v-if="produto.esquemaDoses.length>= 1">
              <h4 class="text-left font-weight-bold">Esquemas de doses:</h4>
              <ul>
                <li class="text-left" v-for="esq in produto.esquemaDoses" :key="esq">{{ esq }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="como-funciona appBG-primary mt-10">
          <div class="row">
            <div class="col-lg-12 mb-8">
              <h2 class="appColor-white font-weight-bold">Como funciona?</h2>
              <p class="appColor-white px-lg-16 px-6">
                <span class="px-lg-16">
                  Aqui você compra suas vacinas online e escolhe o tipo de atendimento: em casa ou na clínica, e agenda a melhor data para garantir sua imunização. Funciona assim:
                </span>
              </p>
            </div>
            <div class="col-lg-3">
              <div class="p-3">
                <div class="appBG-white quadro-branco text-center px-2 py-8">
                  <div class="d-flex justify-content-center">
                    <v-img
                      max-width="48px"
                      src="@/assets/images/cestinha.png"
                    ></v-img>
                  </div>
                  <p class="appColor-primary">
                    Adicione ao carrinho as vacinas desejadas

                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="p-3">
                <div class="appBG-white quadro-branco text-center px-2 py-8">
                  <div class="d-flex justify-content-center">
                    <v-img
                      max-width="48px"
                      src="@/assets/images/caixa.png"
                    ></v-img>
                  </div>
                  <p class="appColor-primary">
                    Faça login para finalizar a compra

                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="p-3">
                <div class="appBG-white quadro-branco text-center px-2 py-8">
                  <div class="d-flex justify-content-center">
                    <v-img
                      max-width="48px"
                      src="@/assets/images/cartao-2.png"
                    ></v-img>
                  </div>
                  <p class="appColor-primary">
                    Pague com cartão de crédito ou boleto

                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="p-3">
                <div class="appBG-white quadro-branco text-center px-2 py-8">
                  <div class="d-flex justify-content-center">
                    <v-img
                      max-width="48px"
                      src="@/assets/images/calendario.png"
                    ></v-img>
                  </div>
                  <p class="appColor-primary">
                    Pronto, você pode agendar sua vacinação a qualquer momento!

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-16">
      <produtos-recomendados />
    </div>
    <div class="mt-16">
      <painel-redes-sociais />
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import headerGeral from "@/components/template/header/header_geral.vue";
import produtosRecomendados from "@/components/detalhes_produto/produtos_recomendados.vue";
import painelRedesSociais from "@/components/home/painel_redes_sociais.vue";
import footerApp from "@/components/template/footer_app.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import LojaService from "../services/loja_service.js";
import Mixin from "@/mixins/vuex_mixin.js";


export default {
  mixins: [CarrinhoMixin, Mixin],
  components: {
    headerGeral,
    produtosRecomendados,
    painelRedesSociais,
    footerApp,
  },
  data: () => ({
    quantidade: 1,
    produto: {
      indicacoes: [],
      esquemaDoses: [],
      contraindicacoes: [],
      prevencao: [],
      recomendacoes: [],
      
    },
  }),
  beforeMount() {
    this.getProduto();
    if (
      this.$route.params.produtoId !== "" &&
      this.$route.params.produtoId !== undefined
    ) {
      this.produtoId = this.$route.params.produtoId;
    } else {
      this.$router.replace({ path: "/" });
    }
  },
  mounted() {
    this.produto.valorTotal = this.produto.valorAtual * this.quantidade;
  },
  watch: {
    quantidade(quantidade) {
      this.produto.valorTotal = this.produto.valorAtual * quantidade;
    },
  },
  methods: {
    adicionaraoCarrinho() {
      let carrinho = {
        titulo: this.produto.titulo,
        valorTotal: this.produto.valorTotal,
        valorUnitario: this.produto.valorAtual,
        quantidade: this.quantidade,
        id: this.produto.produtoId,
      };
      
      this.$_ACTIONS_carrinho(carrinho);
      this.$_ACTIONS_showSnackbarMessage({
        message: "Adicionado ao carrinho",
        color: "sucess",
      });
      
    },

    getProduto() {
      this.loading = true;
      const lojaService = new LojaService();

      const onSucess = (status) => (body) => {
        if (status === 200) {
          // this.totalList = body.data.pagination.num_rows;
          const produto = body.data.loja[0];

          this.produto = {
            produtoId: this.$route.params.produtoId,
            src: produto.foto?? require("@/assets/images/minha-vacina.png"),
            titulo: produto.nome,
            descricao: produto.produtos.reduce((acc, p) => acc + ` - ${p.produto}`, "").slice(2),
            valorAtual: produto.preco,
            valorTotal: produto.preco,
            prevencao: produto.produtos
              .map((p) => p.prevencao)
              .filter((p) => p != null && p != ''),
            indicacoes: produto.produtos
              .map((p) => p.indicacao)
              .filter((p) => p != null),
            recomendacoes: produto.produtos
              .map((p) => p.recomendacoes)
              .filter((p) => p != null),
            contraindicacoes: produto.produtos
              .map((p) => p.contra_indicacao)
              .filter((p) => p != null),
            esquemaDoses: produto.produtos
              .map((p) => [p.produto, p.esquema_doses])
              .map(([produto, esquema_doses]) => `${produto}: ${esquema_doses}`),
          };

        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      lojaService.getOne(
        {
          onSucess,
          onError,
          onEnd,
        },
        this.$route.params.produtoId
        // itemsPerPage,
        // this.search,
        // this.options.ordenacao,
        // this.options.desc
      );
    },
  },
};
</script>

<style scoped>
.v-icon--disabled {
  border: 1px solid #cdcdcd;
}
.como-funciona,
.quadro-branco {
  border-radius: 0.8rem;
}
</style>