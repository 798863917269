<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cancelar compra</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row v-if="canRequestCancelamento()">
          <v-col cols="12">
            <v-text-field v-model="motivo" label="Motivo"></v-text-field>
          </v-col>
          <!-- <v-col cols="12">
            <v-text-field v-model="cartao4ud" label="4 últimos digitos do cartao" max="4" min="4"></v-text-field>
          </v-col> -->
        </v-row>
        <v-row v-else>
          <p class="cancel">
            Prazo de 7 dias excedido conforme política de cancelamento seguindo
            o Decreto nº 7.962/2013 e o Código de Defesa do Consumidor.
          </p>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <template v-if="canRequestCancelamento()">
        <v-btn color="#3E682A" text @click="$emit('close')">
          Não desejo cancelar
        </v-btn>
        <v-btn
          @click="solicitarCancelamento"
          color="#3E682A"
          :loading="loading"
          text
          :disabled="!motivo"
        >
          Sim, desejo cancelar
        </v-btn>
      </template>
      <template v-else>
        <v-btn color="#3E682A" text @click="$emit('close')">
          Ok, entendi
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import CancelamentoService from "@/services/cancelamento_service";

export default {
  mixins: [],
  components: {},
  props: {
    pedido: Object,
  },
  data: () => ({
    motivo: "",
    cartao4ud: "",
    loading: false,
  }),
  beforeMount() {},
  watch: {},
  methods: {
    canRequestCancelamento() {
      const dataSplit = this.pedido.compra_data.slice(0, 10);
      
      const g1 = new Date(dataSplit).getTime();
      const g2 = new Date().getTime() + (7*24*3600);

      return g1 < g2;
    },
    solicitarCancelamento() {
      const request = {
        compra_id: this.pedido.compra_id,
        motivo: this.motivo,
        cartao4ud: this.cartao4ud,
      };

      const cancelamentoService = new CancelamentoService();
      cancelamentoService.cadastrar(
        {
          onSucess: (status) => () => {
            if (status === 400 || status === 500) {
              this.$router.push({ name: "login" });
            }
          },
          onError: (e) => {
            console.log(e);
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("response");
          },
        },
        request
      );
    },
  },
};
</script>

<style scoped>
.remover-todos {
  font-size: 3rem;
}

@media (max-width: 600px) {
  .col-sm-1 {
    width: 10% !important;
    margin-right: 2em;
  }

  .col-sm-11 {
    width: 80% !important;
  }
}
</style>
