<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 mb-2" v-for="(produto, i) in produtos" :key="i">
        <v-card class="px-2 py-3 ">
          <v-img
            :src="produto.src"
            class="text-center"
            aspect-ratio="2"
            contain
          ></v-img>
          <h4 class="text-center mb-0" style="height:50px">{{ produto.titulo | truncate(25, "...") }}</h4>
          <p style="height:50px" class="text-center appColor-secondary">
            {{ produto.descricao | truncate(30, "...") }}
          </p> 
          <!-- <p v-if="produto.promocao">
            <s>R$ {{ produto.valorAnterior }}</s>
          </p> -->
          <h3 class="my-0 text-center font-weight-bold appColor-primary">
            R$
            {{
              parseInt(produto.valorAtual)
                | money({
                  prefix: "",
                  suffix: "",
                  thousands: ".",
                  decimal: ",",
                  precision: 2,
                })
            }}
          </h3>
          <p class="font-weight-bold mb-0" v-if="produto.parcelado">
            ou {{ produto.quantidade_parcelas }}x de R${{
              produto.preco_parcelado
            }}
          </p>
          <v-btn class="appBG-primary appColor-white mt-2" :href="produto.href">
            <v-icon class="app-font-size-icon" left>
              mdi-checkbox-blank-circle
            </v-icon>
            Ver mais
          </v-btn>
        </v-card>
      </div>
    </div>
    <div class="mt-4">
      <input type="hidden" v-model="filtro" @change="handleFiltroChange" />
      <template>
        <div class="text-center">
          <v-pagination
            color="appBG-primary appColor-white"
            v-model="page"
            :length="num_pages"
            :total-visible="7"
            @input="getProdutos"
          ></v-pagination>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LojaService from "../../../../services/loja_service";

export default {
  props: {
    filtro: String,
  },
  data: () => ({
    page: 1,
    per_page: 12,
    search: "",
    ordenacao: "",
    desc: false,
    produtos: [],
    num_pages: 0,
  }),

  watch: {
    filtro(o, n) {
      // watch it
      alert(`de ${o} para ${n}`)
      this.getProdutos();
    },
  },

  mounted() {
    this.getProdutos();
  },

  methods: {
    handleFiltroChange() {
      alert(this.filtro);
    },

    getProdutos() {
      this.loading = true;
      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          (this.num_pages = body.data.pagination.last_page),
            // this.totalList = body.data.pagination.num_rows;
            (this.produtos = body.data.loja.map((pack) => ({
              src: pack.foto?? require("@/assets/images/minha-vacina.png"),
              titulo: pack.nome,
              // junta o titulo dos produtos
              descricao: pack.produtos.reduce(
                (acc, p) => acc + ` - ${p.produto}`,
                ""
              ),
              promocao: true,
              // valorAnterior: "R$ 350",
              valorAtual: pack.preco,
              parcelado: false,
              quantidade_parcelas: "",
              // preco_parcelado: "",
              href: `/detalhes-produto/${pack.pacote_id}`,
            })));
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getAll(
        onSucess,
        onError,
        onEnd,
        this.page,
        this.per_page,
        this.filtro,
        this.ordenacao,
        this.desc
      );
    },
  },
};
</script>

<style scoped>
  .container {
    min-height: 400px !important;
  }
</style>