<template>
  <div>
    <v-item-group>
      <div class="botoes">
        <div v-for="(botao, i) in botoes" :key="i">
          <v-item v-slot="{ active, toggle }">
            <v-btn
              class="px-2 py-3 my-1"
              :class="active ? 'btn-border-primary' : 'btn-border-tranparent'"
              depressed
              width="100%"
              @click="clickBtn(toggle, botao, active)"
            >
              <div
                class="d-flex justify-content-start align-items-center w-100"
              >
                <v-img
                  class="mr-2"
                  contain
                  max-height="30px"
                  max-width="30px"
                  :src="active ? botao.imgAtivo : botao.img"
                ></v-img>
                <p
                  class="font-weight-bold appColor-secondary btn-filtro"
                  :class="active ? 'appColor-primary' : ''"
                >
                  {{ botao.textoBotao }}
                </p>
              </div>
            </v-btn>
          </v-item>
        </div>
      </div>
    </v-item-group>
  </div>
</template>

<script>
import CategoriaService from "../../../../services/categorias_service";
export default {
  data() {
    return {
      
      botoes: []
    };
  },

  beforeMount() {
      this.getCategorias()
  },

  methods: {

    clickBtn(toggle, botao, active) {
      toggle();
      if (!active) {
        this.$emit('mudaFiltroProduto', botao.idCategoria)
      } else {
        this.$emit('mudaFiltroProduto', botao.idCategoria)
      }
    },

    getCategorias() {
      this.loading = true;
      const categoriaService = new CategoriaService();
      this.categorias = [];

      const onSucess = (status) => (body) => {
        console.log(body);
        if (status === 200) {
          this.categorias = body.data.categorias;
          this.botoes = body.data.categorias.map(categoria => ({
            textoBotao: categoria.nome,
            imgAtivo: '',
            img: '',
            idCategoria: categoria.categoria_id,
          }))
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      
      categoriaService.getList({ onSucess, onError, onEnd });
    },
  }
};
</script>