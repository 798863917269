<template>
  <div>
    <div>
      <header-geral />
    </div>

    <div class="p-4 mt-12">
      <div class="p-2 mt-2"></div>
    </div>

    <div class="col-12 pl-12 row container p-8" style="grid-gap: 12px">
      <div class="col-7 align-left row">
        <div class="border col-12 rounded-lg px-6 py-4">
          <h3 class="mb-6 appColor-primary--text">
            Verifique as informações antes de finalizar
          </h3>

          <div class="row">
            <div class="col-5">
              <strong>Meus dados</strong> <br />
              {{ cliente.nome }} <br />
              CPF: {{ cliente.cpf }}
            </div>

            <div class="col-7">
              <strong>Pagamento</strong> <br />

              <v-select
                v-model="meioPagamento"
                :item-text="'name'"
                :item-value="'v'"
                :items="[
                  { name: 'Crédito', v: 1 },
                  /*{ name: 'Boleto', v: 2 },*/
                ]"
                label="Meio de pagamento"
              ></v-select>
            </div>

            <div v-if="meioPagamento == 1" class="col-12">
              <h4>Crédito</h4>

              <div class="row">
                <div class="col-12">
                  <v-text-field
                    label="Nome do titular"
                    hide-details="auto"
                    filled
                    v-model="card.nome"
                  ></v-text-field>
                </div>

                <div class="col-12">
                  <v-text-field
                    label="Número do cartão"
                    hide-details="auto"
                    filled
                    v-model="card.numero"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="Mês de expiração"
                    hide-details="auto"
                    filled
                    v-model="card.exp_m"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="Ano de expiração"
                    hide-details="auto"
                    filled
                    v-model="card.exp_a"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-text-field
                    label="CVV"
                    hide-details="auto"
                    filled
                    v-model="card.cvv"
                  ></v-text-field>
                </div>

                <div class="col-4">
                  <v-select
                    label="Bandeira"
                    hide-details="auto"
                    filled
                    v-model="card.bandeira"
                    item-value="value"
                    :items="bandeiras"
                  >
                    <template v-slot:selection="{ item }">
                      <img :src="item.image" />{{ item.value }}
                    </template>
                    <template v-slot:item="{ item }">
                      <img :src="item.image" />{{ item.value }}
                    </template>
                  </v-select>
                </div>

                <div class="col-4">
                  <v-select
                    v-model="card.parcelas"
                    :item-text="'name'"
                    :item-value="'parcelas'"
                    filled
                    :items="getParcelas()"
                    label="Parcelas"
                  ></v-select>
                </div>
              </div>
            </div>
            <div v-if="meioPagamento == 2" class="col-12">
              <h4>Boleto</h4>

              <div class="row">
                <div class="col-12">
                  <v-text-field
                    label="Nome do titular"
                    hide-details="auto"
                    filled
                    v-model="boleto.nome"
                  ></v-text-field>
                </div>

                <div class="col-12">
                  <v-text-field
                    label="E-mail"
                    hide-details="auto"
                    filled
                    v-model="boleto.email"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="CEP"
                    hide-details="auto"
                    filled
                    @keydown="buscaCep"
                    v-model="boleto.cep"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="Cidade"
                    hide-details="auto"
                    filled
                    v-model="boleto.cidade"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="UF"
                    hide-details="auto"
                    filled
                    v-model="boleto.uf_1"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="Bairro"
                    hide-details="auto"
                    filled
                    v-model="boleto.bairro"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="Rua"
                    hide-details="auto"
                    filled
                    v-model="boleto.rua"
                  ></v-text-field>
                </div>

                <div class="col-6">
                  <v-text-field
                    label="Número"
                    hide-details="auto"
                    filled
                    v-model="boleto.numero"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border col-12 mt-4 rounded-lg py-4">
          <div class="px-4 rowtb row border-bottom">
            <span class="coltb col-5">Pacote</span>
            <span class="coltb col-3">Quantidade</span>
            <span class="coltb col-4">Preço</span>
          </div>

          <div
            class="row rowtb mx-6"
            v-for="pacote in carrinho"
            :key="pacote.id"
          >
            <div class="col-6 coltb row p-3">
              <div class="border rounded mr-4 py-3 px-1" style="width: 60px">
                <v-img
                  :src="imgVacina"
                  class="text-center"
                  aspect-ratio="2"
                  contain
                ></v-img>
              </div>
              <div class="pt-4">{{ pacote.titulo }}</div>
            </div>
            <div class="coltb col-3">
              <div class="pt-4">{{ pacote.quantidade | fillLeft(2) }}</div>
            </div>
            <div class="coltb col-3">
              <div class="pt-4">
                R$
                {{
                  parseFloat(pacote.valorTotal)
                    | money({
                      prefix: "",
                      suffix: "",
                      thousands: ".",
                      decimal: ",",
                      precision: 2,
                    })
                }}

                <v-icon>mdi-trash</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-3 p-0">
        <div class="border rounded-lg mt-0 pt-0">
          <div
            class="
              rounded-t-lg
              border-bottom
              mt-0
              py-1
              px-2
              grey
              lighten-1
              mb-4
            "
          >
            <h3 class="appColor-primary--text">Resumo do pedido</h3>
          </div>

          <p
            v-for="pacote in carrinho"
            :key="pacote.id"
            class="w-100 px-4 py-1 align-left"
          >
            {{ pacote.titulo }}
          </p>

          <div class="p-2 px-6 d-flex justify-between border-top">
            <strong class="mr-12">TOTAL</strong>
            <strong class="ml-12 appColor-primary--text"
              >R$
              {{
                getValorTotal()
                  | money({
                    prefix: "",
                    suffix: "",
                    thousands: ".",
                    decimal: ",",
                    precision: 2,
                  })
              }}</strong
            >
          </div>
          <div v-if="meioPagamento == 1" class="border-top pt-4">
            à vista <br />
            <div class="mt-n1 mb-0 h3 appColor-primary--text">
              R$
              {{
                getValorTotal()
                  | money({
                    prefix: "",
                    suffix: "",
                    thousands: ".",
                    decimal: ",",
                    precision: 2,
                  })
              }}
            </div>

            <span class="d-block mt-2">
              parcelado em {{ card.parcelas }}x de
            </span>

            <div class="mt-n1 mb-0 h3 appColor-primary--text mb-8">
              R$
              {{
                getParcela(card.parcelas).valor
                  | money({
                    prefix: "",
                    suffix: "",
                    thousands: ".",
                    decimal: ",",
                    precision: 2,
                  })
              }}
            </div>
          </div>
        </div>
        <v-btn
          class="col-12 mt-3 px-3 py-6 btn-compra"
          :disabled="loadCheckout"
          :loading="loadCheckout"
          @click="finalizarCompra"
          >FINALIZAR PEDIDO</v-btn
        >
        <p class="cancel">
          <b>POLÍTICA DE CANCELAMENTO</b><br>
          Em respeito ao Direito do Consumidor, a São Carlos Vacinas possui Política de Cancelamento para as compras feitas em ambiente eletrônico, o que permite o exercício do direito de arrependimento de forma adequada e eficaz pelos nossos clientes. O prazo de cancelamento é de <b>sete dias</b> a partir da data da compra a partir do preenchimento do formulário disponibilizado abaixo.
        </p>
      </div>
    </div>

    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import headerGeral from "@/components/template/header/header_geral.vue";
import footerApp from "@/components/template/footer_app.vue";
import ClienteService from "@/services/cliente_service";
import CEPService from "@/services/cep_service";
import CompraService from "@/services/compras_service";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import SnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  components: {
    headerGeral,
    footerApp,
  },

  mixins: [VuexUsuarioMixin, VuexTokenMixin, CarrinhoMixin, SnackbarMixin],

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push({ name: "login", params: { loginCompra: true } });
    }
  },

  mounted() {
    this.loadCompraInfo();
  },

  data: () => ({
    checkoutLeave: false,
    loadCheckout: false,
    imgVacina: require("@/assets/images/minha-vacina.png"),
    meioPagamento: "",
    cliente: {},
    carrinho: {},
    bandeiras: [
      { value: "Visa", img: require("@/assets/icons/visa.svg") },
      { value: "Master", img: require("@/assets/icons/mastercard.svg") },
      { value: "Amex", img: require("@/assets/icons/amex.svg") },
      { value: "Elo", img: require("@/assets/icons/elo.svg") },
      { value: "Aura", img: require("@/assets/icons/generic.svg") },
      { value: "JCB", img: require("@/assets/icons/jcb.svg") },
      { value: "Diners", img: require("@/assets/icons/diners.svg") },
      { value: "Discover", img: require("@/assets/icons/discover.svg") },
      { value: "Hipercard", img: require("@/assets/icons/hipercard.svg") },
    ],
    card: {
      exp_m: "",
      exp_a: "",
      nome: "",
      cvv: "",
      numero: "",
      parcelas: 1,
      bandeira: "Visa",
    },
    boleto: {
      email: "",
      cep: "",
      uf: "",
      uf_1: "",
      cidade: "",
      rua: "",
      bairro: "",
      numero: "",
    },
  }),

  filters: {
    fillLeft: function (value, qtd) {
      let v = value.toString();
      if (v.length < qtd) {
        return "0".repeat(qtd - v.length) + v;
      }

      return v;
    },

    formatData: function (data) {
      return data.substr(0, 10).split("-").reverse().join("/");
    },
  },

  methods: {
    finalizarCompra() {
      const compra = {
        pacotes: this.carrinho.map((pacote) => ({
          pacote_id: pacote.id,
          quantidade: pacote.quantidade,
        })),
      };

      const compraService = new CompraService();

      if (this.meioPagamento == 1 || this.meioPagamento == 2) {
        const meioPag = this.meioPagamento == 1 ? "card" : "boleto";
        for (let key in this[meioPag]) {
          if (
            this[meioPag][key] === "" ||
            this[meioPag][key] === null ||
            this[meioPag][key] === undefined
          ) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Preencha os dados corretamente",
              color: "error",
            });
            return;
          }
        }

        this.loadCheckout = true;
        compra[meioPag] = this[meioPag];
        compraService.comprar(
          {
            onSucess: (status) => (body) => {
              if (status == 200) {
                this.checkoutLeave = body.code == 20000;

                this.$_ACTIONS_showSnackbarMessage({
                  message: body.message,
                  color: this.checkoutLeave ? "sucess" : "error",
                });
              }
            },

            onEnd: () => {
              if (this.checkoutLeave) {
                this.$router.push({ name: "meus-pedidos" });
              }
            },

            onError: e => {
              this.$_ACTIONS_showSnackbarMessage({
                message: e.message,
                color: "error",
              });
              this.loadCheckout = false;
            },
          },
          compra,
          meioPag
        );
      }
    },

    loadCompraInfo() {
      this.loadUser();
      this.loadCarrinho();
    },

    getValorTotal() {
      try {
        const valorTotal = this.carrinho.reduce(
          (acc, t) => acc + parseFloat(t.valorTotal),
          0
        );
        return valorTotal;
      } catch (e) {
        return 0;
      }
    },

    getParcelas() {
      const valorTotal = this.getValorTotal();
      const parcelas = [];
      const qtd = 12;

      for (let i = 1; i <= qtd; i++) {
        const parcelaValor = valorTotal / i;
        parcelas.push({
          name: `${i}x de ${parcelaValor.toFixed(2)}`,
          valor: parcelaValor.toFixed(2),
          parcelas: i,
        });
      }

      return parcelas;
    },

    getParcela(parcela) {
      const parcelaAtual = this.getParcelas().filter(
        (p) => p.parcelas == parcela
      );
      if (parcelaAtual.length >= 1) {
        return parcelaAtual[0];
      } else {
        return 0;
      }
    },

    converterEstados(val) {
      let data = "";

      switch (val.toUpperCase()) {
        case "AC":
          data = "Acre";
          break;
        case "AL":
          data = "Alagoas";
          break;
        case "AM":
          data = "Amazonas";
          break;
        case "AP":
          data = "Amapa";
          break;
        case "BA":
          data = "Bahia";
          break;
        case "CE":
          data = "Ceara";
          break;
        case "DF":
          data = "Distrito Federal";
          break;
        case "ES":
          data = "Espirito Santo";
          break;
        case "GO":
          data = "Goias";
          break;
        case "MA":
          data = "Maranhao";
          break;
        case "MG":
          data = "Minas Gerais";
          break;
        case "MS":
          data = "Mato Grosso do Sul";
          break;
        case "MT":
          data = "Mato Grosso";
          break;
        case "PA":
          data = "Para";
          break;
        case "PB":
          data = "Paraiba";
          break;
        case "PE":
          data = "Pernambuco";
          break;
        case "PI":
          data = "Piaui";
          break;
        case "PR":
          data = "Parana";
          break;
        case "RJ":
          data = "Rio de Janeiro";
          break;
        case "RN":
          data = "Rio Grande do Norte";
          break;
        case "RO":
          data = "Rondonia";
          break;
        case "RR":
          data = "Roraima";
          break;
        case "RS":
          data = "Rio Grande do Sul";
          break;
        case "SC":
          data = "Santa Catarina";
          break;
        case "SE":
          data = "Sergipe";
          break;
        case "SP":
          data = "São Paulo";
          break;
        case "TO":
          data = "Tocantins";
          break;
      }

      return data;
    },

    buscaCep() {
      this.cep = this.boleto.cep;
      const cepService = new CEPService();
      this.loading = true;
      cepService.buscaCep(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.boleto.rua = body.logradouro;
              this.boleto.estado = body.uf;
              this.boleto.cidade = body.localidade;
              this.boleto.bairro = body.bairro;
              this.boleto.complemento = body.complemento;
              this.boleto.uf = body.uf;
              this.boleto.uf_1 = this.converterEstados(body.uf);
            }
          },
          onError: (error) => {
            return error;
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.cep.replace("-", "").replace(".", "")
      );
    },

    loadCarrinho() {
      this.carrinho = this.$_GETTERS_carrinho;
    },

    loadUser() {
      const clienteService = new ClienteService();

      clienteService.get(
        {
          onSucess: (status) => (body) => {
            if (status == 200) {
              this.cliente = body.data.clientes[0];
            } else {
              // this.$router.push({name: 'login'})
            }
          },

          onError: (error) => {
            console.log(error);
          },

          onEnd: () => {
            //
          },
        },
        this.$_GETTERS_usuario.id
      );
    },

    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },
  },
};
</script>

<style scoped>
.align-left {
  text-align: left;
}

.btn-compra {
  background-color: #a92429 !important;
  color: #fff;
  font-weight: bold;
  font-size: 1.3rem;
}

h3,
h4 {
  font-size: 22px;
}

@media (max-width: 600px) {
  .p-4 {
    padding-top: 0 !important;
  }

  .col-3,
  .col-5,
  .col-6,
  .col-7,
  .col-12 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 1 !important;
  }

  .row {
    display: flex !important;
    flex-flow: column !important;
  }

  .rowtb {
    display: flex !important;
    flex-flow: row !important;
  }

  .coltb {
  }
}
</style>