<template>
  <div>
    <div class="d-none d-lg-block">
      <botoes-filtro-desktop :filtro="filtro" @mudaFiltroProduto="emiteFiltro"/>
    </div>
    <div class="d-block d-lg-none">
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-btn
            color="appColor-white appBG-primary"
            @click="$emit('abaFiltro')"
          >
            <v-icon class="app-font-size-icon" left>mdi-filter-outline</v-icon>
            Filtrar
          </v-btn>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import botoesFiltroDesktop from "@/components/home/filtro_geral/tabs_components/botoes_filtro_desktop.vue";

export default {
  components: {
    botoesFiltroDesktop,
  },

  props: {
    filtro: String
  },

  methods: {
    emiteFiltro(id) {
      this.$emit('mudaFiltroProduto', id)
    }
  }
};
</script>

