<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <h1 class="text-left mb-2">Filtrar por</h1>
          <tabs @abaFiltro="$emit('abaFiltro')" :filtro="filtro" @mudaFiltroProduto="mudaFiltroProduto"/>
        </div>
        <div class="col-lg-8">
          <tabs-itens :filtro="filtro" :media="calcularMedia()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabs from "@/components/home/filtro_geral/tabs_components/tabs.vue";
import tabsItens from "@/components/home/filtro_geral/tabs_components/tabs_itens.vue";

export default {
  props: {
    filtro: String
  },
  components: {
    tabs,
    tabsItens,
  },
  data: () => ({
  }),
  methods: {
    calcularMedia() {
      let media = "";
      if (window.matchMedia("(min-width: 1264px)").matches) {
        media = "lg";
      }
      if (
        window.matchMedia("(min-width: 960px) and (max-width: 1263px)").matches
      ) {
        media = "md";
      }
      if (
        window.matchMedia("(min-width: 600px) and (max-width: 959px)").matches
      ) {
        media = "sm";
      }
      if (window.matchMedia("(max-width: 599px)").matches) {
        media = "xs";
      }
      return media;
    },

    mudaFiltroProduto(id) {
      this.$emit('mudaFiltroProduto', id)
    }
  },
};
</script>