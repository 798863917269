<template>
  <div>
    <div>
      <header-geral />
    </div>

    <div class="p-4 mt-12">
      <div class="p-2 mt-2"></div>
    </div>

    <div class="w-75 container">
      <h3 class="mb-6">DADOS DO PEDIDO</h3>

      <div class="container border rounded-lg outlined" style="min-height: 500px">
        <div class="row container">
          <div class="col">
            <strong>Forma de pagamento</strong>
            <p></p>
          </div>
          <div class="col">
            <strong>Resumo do pedido</strong>

            <p class="row p-0">
              <span class="col-5">Total de itens:</span>
              <span class="col-3"></span>
              <span class="col-3">
                {{
                  pedido.pacotes
                    .map(
                      (pacote) =>
                        pacote.quantidade *
                        pacote.produtos.reduce(
                          (acc, produto) => acc + parseInt(produto.quantidade),
                          0
                        )
                    )
                    .reduce((acc, c) => acc + c, 0)
                }}
              </span>
            </p>

            <p class="row p-0">
              <strong class="col-4">Total geral:</strong>
              <small class="col-4"></small>
              <strong class="col-4">
                R${{
                  parseFloat(pedido.preco)
                    | money({
                      prefix: "",
                      suffix: "",
                      thousands: ".",
                      decimal: ",",
                      precision: 2,
                    })
                }}
              </strong>
            </p>
          </div>
          <div class="col">
            <p class="row p-0">
              <strong class="col-4">Status:</strong>
              <strong class="col-4">
                {{ pedido.compra_status | statusPagamento }}
              </strong>
            </p>
          </div>
        </div>

        <v-card class="col-12 d-flex flex-column mb-4" style="height: fit-content" elevation="0">
          <div class="row grey--text text--darken-2" style="padding: 0">
            <small class="col"
              >Pedido n° {{ pedido.carrinho_id | fillLeft(8) }}</small
            >
            <small value="data" class="col"
              >Realizado no dia {{ pedido.compra_data | formatData }}</small
            >
            <small id="dias" value="5" class="col">
              Realizado no dia {{ pedido.compra_data }}
            </small>
            <div class="col">
              <p class="cancel">
                <b>POLÍTICA DE CANCELAMENTO</b><br />
                Em respeito ao Direito do Consumidor, a São Carlos Vacinas
                possui Política de Cancelamento para as compras feitas em
                ambiente eletrônico, o que permite o exercício do direito de
                arrependimento de forma adequada e eficaz pelos nossos clientes.
                O prazo de cancelamento é de <b>sete dias</b> a partir da data
                da compra a partir do preenchimento do formulário
                disponibilizado abaixo.
              </p>
              <v-btn
                @click="dialog = true"
                v-if="!isAble"
                class="appBG-primary comprar-button white--text mt-3"
                >SOLICITAR CANCELAMENTO</v-btn
              >
              
            </div>
          </div>

          <v-card-text
            class="row cardresum"
            v-for="pacote in pedido.pacotes"
            :key="pacote.pacote_id"
          >
            <div class="col-2 border card-img-wrapper">
              <v-img
                width="40"
                class="mx-auto"
                src="@/assets/images/minha-vacina.png"
              ></v-img>
            </div>

            <div class="col">
              <h4>x{{ pacote.quantidade }} {{ pacote.pacote_nome }}</h4>
              <p>
                {{
                  pacote.produtos
                    .reduce(
                      (acc, c) => acc + ` - ${c.quantidade}x ${c.produto_nome}`,
                      ""
                    )
                    .substr(2)
                }}
              </p>
            </div>
          </v-card-text>

          <div class="row p-2 d-flex align-content-right">
            <v-btn
              class="appBG-primary comprar-button white--text"
              @click="addToCartBatch"
              >Comprar novamente</v-btn
            >
            <v-btn
              target="_blank"
              :href="pedido.boleto"
              v-if="pedido.metodo_pagamento == 'BOLETO'"
              class="ml-4 appBG-primary comprar-button white--text"
              >Imprimir boleto</v-btn
            >
          </div>
        </v-card>
      </div>
    </div>

    <!-- MODAL - AGENDA -->
    <v-dialog v-model="dialog" height="250px" max-width="500px">
      <modal-cancelamento
        v-if="dialog"
        :pedido="pedido"
        @close="dialog = false"
        @response="response"
      />
    </v-dialog>

    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import headerGeral from "@/components/template/header/header_geral.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import footerApp from "@/components/template/footer_app.vue";
import ModalCancelamento from "@/components/cancelamento/modal_cancelamento.vue";

import SnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import CompraService from "@/services/compras_service";

export default {
  components: {
    headerGeral,
    footerApp,
    ModalCancelamento,
  },
  mixins: [VuexUsuarioMixin, VuexTokenMixin, CarrinhoMixin,SnackbarMixin],

  methods: {
    addToCartBatch() {
      this.pedido.pacotes.map((p) => {
        let carrinho = {
          titulo: p.pacote_nome,
          // valorTotal: parseFloat(p.preco) * p.quantidade,
          valorUnitario: parseFloat(p.preco),
          quantidade: parseInt(p.quantidade),
          id: p.pacote_id,
        };

        this.$_ACTIONS_carrinho(carrinho);
      });
    },

    solicitarCancelamento() {
      //const compra_id = this.pedido.compra_id;
    },

    response() {
      this.isAble = false;
      this.$_ACTIONS_showSnackbarMessage({
        message: "Solicitação de cancelamento da compra realizada.",
        color: "sucess",
      });
      this.dialog = false;
    },

    getPedido() {
      const compraService = new CompraService();
      compraService.getOne(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.pedido = body.data.compras[0];
              console.log(this.pedido);
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {},
        },
        this.$route.params.id
      );
    },
  },

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push("/");
    }
  },

  mounted() {
    this.getPedido();
  },

  filters: {
    fillLeft: function(value, qtd) {
      let v = value.toString();
      if (v.length < qtd) {
        return "0".repeat(qtd - v.length) + v;
      }

      return v;
    },

    statusPagamento(status) {
      const stts = {
        waiting: "aguardando",
        paid: "pago",
        declined: "recusado",
      };

      return stts[status.toLowerCase()];
    },

    formatData: function(data) {
      return data
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/");
    },
  },

  data: () => ({
    pedido: {
      carrinho_id: -1,
      compra_data: "",
      forma_de_pagamento: "",
      valor_total: "",
      pacotes: [],
    },
    isAble: false,
    dialog: false,
  }),
};
</script>

<style scoped>
.cardresum {
  margin-top: -18rem;
}
.col {
  text-align: left;
}

small {
  font-size: 14px;
}

p.p-0 {
  line-height: 1;
  padding: 0;
}
</style>
