<template>
  <div>
    <div class="container">
      <div class="row my-8">
        <div class="col-lg-6">
          <h2 class="text-left mr-2">Confira nossos serviços especiais</h2>
        </div>
        <div class="col-lg-6">
          <div class="pl-3" style="border-left: 3px solid #a92429">
            <p class="appColor-secondary font-weight-bold text-left">
              Agora você tem a oportunidade de desfrutar da qualidade do
              atendimento e do serviço de vacinação individual e coletiva da São
              Carlos.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="row solucoes mt-4">
            <div class="col-lg-4">
              <v-img max-width="150px" :src="solucoes"></v-img>
            </div>
            <div class="col-lg-8">
              <h4 class="text-left pt-3">Soluções para empresas</h4>
              <p class="text-left">
                Proteção para todos os profissionais da sua empresa. Conte com a
                nossa expertise para toda a sua equipe ter vacinação em dia.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row vacinasViajantes mt-4">
            <div class="col-lg-4">
              <v-img max-width="150px" :src="vacinasViajantes"></v-img>
            </div>
            <div class="col-lg-8">
              <h4 class="text-left pt-3">Vacinas para viajantes</h4>
              <p class="text-left">
                Viagem marcada? É melhor ficar de olho nas vacinas necessárias!
                Cada pais possui a sua lista de exigência e estamos preparados
                para recebê-lo.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row cartao mt-4">
            <div class="col-lg-4">
              <v-img max-width="150px" :src="cartao"></v-img>
            </div>
            <div class="col-lg-8">
              <h4 class="text-left pt-3">Cartão Presente</h4>
              <p class="text-left">
                Uma forma pensada com muito carinho para presentear amigos e
                família com reforço da imunidade, valorizando assim a saúde e a
                proteção.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row homecare mt-4">
            <div class="col-lg-4">
              <v-img max-width="150px" :src="homecare"></v-img>
            </div>
            <div class="col-lg-8">
              <h4 class="text-left pt-3">Atendimento Home Care</h4>
              <p class="text-left">
                Pensando no conforto/comodidade para os clientes, a São Carlos
                vai até você. Ligue para a Clinica mais próxima de você e agende
                com a nossa equipe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cartaoImg from "@/assets/images/cartao.png";
import homeCareImg from "@/assets/images/homecare.png";
import solucoesImg from "@/assets/images/solucoes.png";
import vacinasViajantesImg from "@/assets/images/vacinas-viajantes.png";

export default {
  data: () => ({
    cartao: cartaoImg,
    homecare: homeCareImg,
    solucoes: solucoesImg,
    vacinasViajantes: vacinasViajantesImg,
  }),
};
</script>