<template>
  <v-card class="vcard-small">
    <v-card-title>
      <span class="headline">Agendar horário</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="produto_id"
              item-text="produto_nome"
              item-value="produto_id"
              :items="agenda.vacinas"
              label="Vacina"
            ></v-select>            
          </v-col>
        </v-row>

        <v-col col="12">
            <v-checkbox
              v-model="checkboxAgendarParaOutro"
              :label="'A vacina será aplicada em outra pessoa'"
            ></v-checkbox>
          </v-col>


        <v-col v-show="checkboxAgendarParaOutro" cols="12">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Nome da pessoa"
                  v-model="nome_pessoa"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field label="CPF" v-model="cpf_pessoa"></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Data de nascimento"
                  :type="'date'"
                  v-model="data_nascimento_pessoa"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('cancel')"> Cancelar </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="agendar">
        Agendar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import AgendamentoService from "@/services/agendamento_service.js";

export default {
  mixins: [Mixin],
  props: {
    agenda: Object,
  },
  mounted() {},
  data: () => ({
    agenda_id: "",
    produto_id: -1,
    loading: false,
    dialog: false,
    nome_pessoa: "",
    cpf_pessoa: "",
    data_nascimento_pessoa: null,
    checkboxAgendarParaOutro: false,
  }),
  methods: {
    agendar() {
      // const categoriaService = new CategoriaService()
      this.loading = true;

      const aplicacao = this.agenda.vacinas.filter(
        (v) => (this.produto_id = v.produto_id)
      )[0];

      const formData = {
        agenda_id: this.agenda.agenda.id,
        aplicacao_id: aplicacao.aplicacao_id,
        produto_id: aplicacao.produto_id,
        nome_paciente: this.nome_pessoa,
        cpf_paciente: this.cpf_pessoa,
        data_nascimento: this.data_nascimento_pessoa,
      };

      const agendamentoService = new AgendamentoService();

      agendamentoService.register(
        {
          onSucess: (status) => (body) => {
            if (status == 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "error",
              });
            }

            this.$emit("response");
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
            this.$emit("close");
          },
        },
        formData
      );
    },
  },
};
</script>