<template>
  <div>
    <h2 class="font-weight-bold mb-8">
      Saiba o que nossos clientes estão falando
    </h2>
    <v-sheet class="mx-auto container" elevation="0">
      <v-slide-group v-model="model" class="pa-4" show-arrows>
        <v-slide-item v-for="(depoimento, n) in depoimentos" :key="n">
          <v-card class="pa-4 ma-4" max-width="320px" elevation="0">
            <h1 class="font-weight-bold">"</h1>
            <p>{{ depoimento.texto }}</p>
            <v-rating
              color="warning"
              readonly
              :length="depoimento.ranking"
              size="16"
              :value="depoimento.ranking"
            ></v-rating>
            <div class="d-flex justify-content-center">
              <v-img :src="exemplo" max-width="90px"></v-img>
            </div>
            <h3 class="font-weight-bold">{{depoimento.clienteNome}}</h3>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
  </div>
</template>

<script>
import exemploImg from "@/assets/images/img-exemplo.png";

export default {
  data: () => ({
    model: null,
    exemplo: exemploImg,
    depoimentos: [
      {
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.",
        ranking: 5,
        clienteNome: "Lorem Ipsum",
      },
      {
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.",
        ranking: 5,
        clienteNome: "Lorem Ipsum",
      },
      {
        texto:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum sus.",
        ranking: 5,
        clienteNome: "Lorem Ipsum",
      },
    ],
  }),
};
</script>