<template>
  <div>
    <div class="calendario-vacinal">
      <div class="row">
        <div
          class="col-lg-6 bg-calendario"
          :style="'background-image: url(' + bgCalendario + ');'"
        >
          <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-10">
              <div class="text-center my-5">
                <div class="d-flex align-items-center">
                  <v-img
                    class="mr-2"
                    max-width="64px"
                    max-height="56px"
                    :src="icone"
                  ></v-img>
                  <h1 class="appColor-white pr-4 text-left">
                    Calendário de vacinas
                  </h1>
                </div>
              </div>

              <!-- TABS -->
              <v-tabs
                id="tabs-calendarioVacinal"
                class="mt-16 pt-14"
                slider-color="transparent"
                v-model="model"
                vertical
              >
                <div class="row">
                  <div class="col-lg-6">
                    <v-tab
                      class="
                        d-none
                        justify-content-center
                        align-items-center
                        btnCalendario
                      "
                       
                      active-class="btnCalendario-ativo"
                      :href="`#tab-1`" 
                    >
                      <p  class="texto-tabCalendario">Prematuro</p>
                    </v-tab>
                    <v-tab
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        btnCalendario
                      "
                      active-class="btnCalendario-ativo"
                      :href="`#tab-2`"
                    >
                      <p class="texto-tabCalendario">Criança 0 a 10 anos</p>
                    </v-tab>
                    <v-tab
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        btnCalendario
                      "
                      active-class="btnCalendario-ativo"
                      :href="`#tab-3`"
                    >
                      <p class="texto-tabCalendario">Adolescente 10 a 19 anos</p>
                    </v-tab>
                    <v-tab
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        btnCalendario
                      "
                      active-class="btnCalendario-ativo"
                      :href="`#tab-4`"
                    >
                      <p class="texto-tabCalendario">Gestante</p>
                    </v-tab>
                  </div>
                  <div class="col-lg-6">
                    <v-tab
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        btnCalendario
                      "
                      active-class="btnCalendario-ativo"
                      :href="`#tab-5`"
                    >
                      <p class="texto-tabCalendario">Adulto 20 a 59 anos</p>
                    </v-tab>
                    <v-tab
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        btnCalendario
                      "
                      active-class="btnCalendario-ativo"
                      :href="`#tab-6`"
                    >
                      <p class="texto-tabCalendario">Idoso mais de 60 anos</p>
                    </v-tab>
                    <v-tab
                      class="
                        d-none
                        justify-content-center
                        align-items-center
                        btnCalendario
                      "
                      active-class="btnCalendario-ativo"
                      :href="`#tab-7`"
                    >
                      <p class="texto-tabCalendario">
                        Pacientes Especiais
                      </p>
                    </v-tab>
                  </div>
                </div>
              </v-tabs>

            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <!-- SESSÃO DIALOG CONTATO -->
              <div class="row appBG-secondary">
                <div class="col-lg-6">
                  <p class="font-weight-bold appColor-white text-left">
                    Para mais detalhes fale conosco através dos nossos canais de
                    comunicação
                  </p>
                </div>
                <div class="col-lg-4">
                  <v-dialog v-model="dialogContatos" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="appBG-primary appColor-white mt-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="app-font-size-icon" left>
                          mdi-checkbox-blank-circle
                        </v-icon>
                        Contato
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5 appBG-primary appColor-white">
                        Contatos
                      </v-card-title>
                      <v-card-text>
                        <div class="d-flex justify-content-between">
                          <ul class="list-unstyled pl-0 py-4">
                            <li>
                              <a href="tel:8540091616">
                                <div class="d-flex align-items-center">
                                  <v-icon class="mr-1">mdi-phone</v-icon>
                                  <p class="appColor-secondary font-weight-bold mr-4">(85) 4009.1616</p>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="https://api.whatsapp.com/send?phone=5585992114835" target="_blank">
                                <div class="d-flex align-items-center">
                                  <v-icon class="mr-1">mdi-whatsapp</v-icon>
                                  <p class="appColor-secondary font-weight-bold mr-4">(85) 99211.4835</p>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="mailto:vacinas@saocarlosimagem.com.br">
                                <div class="d-flex align-items-center">
                                  <v-icon class="mr-1">mdi-email-outline</v-icon>
                                  <p class="appColor-secondary font-weight-bold mr-4">vacinas@saocarlosimagem.com.br</p>
                                </div>
                              </a>
                            </li>
                          </ul>
                          <div class="d-flex align-items-center">
                            <a href="https://www.facebook.com/saocarlosvacinas/" target="_blank">
                              <v-icon class="mr-3">mdi-facebook</v-icon>
                            </a>
                            <a href="https://www.instagram.com/saocarlosvacinas/" target="_blank">
                              <v-icon class="mr-3">mdi-instagram</v-icon>
                            </a>
                            <a href="https://www.linkedin.com/company/saocarlosmedicinaesaude/" target="_blank">
                              <v-icon class="mr-3">mdi-linkedin</v-icon>
                            </a>
                          </div>
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="appBG-primary appColor-white" text @click="dialogContatos = false">
                          Fechar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>                
                </div>
                <div class="col-lg-2"></div>
              </div>
            </div>
            <div class="col-lg-10">
              <v-tabs-items class="mt-4" v-model="model">
                <v-tab-item :value="`tab-1`">
                  <v-card flat>
                    <tab-prematuros  v-show="false" />
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="`tab-2`">
                  <v-card flat>
                    <tab-criancas />                    
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="`tab-3`">
                  <v-card flat>
                    <tab-adolecentes />
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="`tab-4`">
                  <v-card flat>
                    <tab-gestantes />                    
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="`tab-5`">
                  <v-card flat>
                    <tab-adultos />                    
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="`tab-6`">
                  <v-card flat>
                    <tab-idosos />
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="`tab-7`">
                  <v-card flat>
                    <tab-pacientes-especiais v-show="false" />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgCalendarioVacinal from "@/assets/images/calendario-vacinal-bg.png";
import iconeCalendario from "@/assets/images/icon-calendario.png";
import tabAdolecentes from "@/components/home/calendario_vacinal/conteudo_tabs/tab_adolecentes.vue";
import tabGestantes from "@/components/home/calendario_vacinal/conteudo_tabs/tab_gestantes.vue";
import tabAdultos from "@/components/home/calendario_vacinal/conteudo_tabs/tab_adultos.vue";
import tabIdosos from "@/components/home/calendario_vacinal/conteudo_tabs/tab_idosos.vue";
import tabCriancas from "@/components/home/calendario_vacinal/conteudo_tabs/tab_criancas.vue";
import tabPrematuros from "@/components/home/calendario_vacinal/conteudo_tabs/tab_prematuros.vue";
import tabPacientesEspeciais from "@/components/home/calendario_vacinal/conteudo_tabs/tab_pacientes_especiais.vue";

export default {
  components: {
    tabAdolecentes,
    tabGestantes,
    tabPacientesEspeciais,
    tabAdultos,
    tabIdosos,
    tabCriancas,
    tabPrematuros
  },
  data() {
    return {
      bgCalendario: bgCalendarioVacinal,
      icone: iconeCalendario,
      model: "tab-5",
      dialogContatos: false,
    };
  },
};
</script>

<style scoped>
.bg-calendario {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding-bottom: 10rem;
}
.texto-tabCalendario {
  text-transform: none;
  font-weight: 700;
  display: block;
  white-space: normal;
  line-height: 1.2;
}
.btnCalendario {
  background-color: transparent;
  border: 3px solid #fff;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.btnCalendario .texto-tabCalendario {
  color: #fff;
}
.btnCalendario-ativo {
  background-color: #fff;
  border: 3px solid #fff;
  border-radius: 0.5rem;
}

.btnCalendario-ativo .texto-tabCalendario {
  color: #a92429;
}


@media (max-width: 600px) {

  .calendario-vacinal {
    display: none;
  }

}
</style>