<template>
  <div>
    <v-container>
      <div
        class="d-flex ctner justify-content-between align-items-center appBG-white"
      >
        <router-link to="/">
          <v-img
            max-width="295px"
            src="@/assets/images/logo-saocarlos.png"
          ></v-img>
        </router-link>
        <div class="menu">
          <ul class="d-flex justify-content-between list">
            <li v-if="!isGuest()" class="list-group-item border-0">
              <a href="/meus-pedidos">
                <p
                  class="
                    appColor-secondary
                    item-menu-font-size
                    font-weight-bold
                  "
                >
                  Meus pedidos
                </p>
              </a>
            </li>
            <li v-if="!isGuest()" class="list-group-item border-0">
              <router-link  to="/agendamentos">
                <p
                  class="
                    appColor-secondary
                    item-menu-font-size
                    font-weight-bold
                  "
                >
                  Agendamentos
                </p>
              </router-link>
            </li>
            <li v-else class="list-group-item border-0">
              <router-link to="/">
                <p
                  class="
                    appColor-secondary
                    item-menu-font-size
                    font-weight-bold
                  "
                >
                  Doença previnível
                </p>
              </router-link>
            </li>
            <li class="list-group-item border-0">
              <router-link v-if="!isGuest()" to="/agendamento">
                <p
                  class="
                    appColor-secondary
                    item-menu-font-size
                    font-weight-bold
                  "
                >
                  Agendar vacina
                </p>
              </router-link>
            </li>
            <li class="list-group-item border-0">
              <router-link v-if="!isGuest()" to="/cartao-vacina">
                <p
                  class="
                    appColor-secondary
                    item-menu-font-size
                    font-weight-bold
                  "
                >
                  Cartão de vacina
                </p>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="area-usuario d-flex align-items-center">
          <a>
            <div class="login-cadastro d-flex">
              <div class="text-right mr-3">
                <div @click="$router.push({name: 'login'})" class="font-weight-bold appColor-primary" v-if="isGuest()">
                  Entrar/Cadastrar
                </div>
                
                <!-- <div @click="$router.push({name: 'meus-pedidos'})" class="appColor-primary" v-else>Meus pedidos</div> -->

              </div>
              <v-icon v-if="!isGuest()" @click="$router.push({name: 'dashboard'})" class="appColor-primary icon-font-size mr-3"
                >mdi-account</v-icon
              >
            </div>
          </a>
          
          <v-btn elevation="0" @click.stop="$emit('carrinhoModal')">
            <v-badge color="#a92429" :content="quantidade_produtos">
              <v-icon class="appColor-secondary icon-font-size"
                >mdi-basket</v-icon
              >
            </v-badge>
          </v-btn>

        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
// import AuthService from "@/services/auth_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  computed: {
    quantidade_produtos() {
      return this.$store.state.carrinho.length || 0
    },

    user: () => this.$store.state.usuario,

  },
  methods: {
    isGuest() {
      return !this.$store.state.token
    },
    autenticarLogin() {
      let token = this.$_GETTERS_token.length;
      if (token == '') {
        return this.$router.replace("login");
      } else {
        return this.$router.replace("meus-pedidos")
      }
    }
  }
};
</script>

<style scoped>
.item-menu-font-size {
  font-size: 1rem;
}
.area-usuario .icon-font-size {
  font-size: 1.8rem;
}

@media (max-width: 600px) {
  .ctner {
    flex-flow: column !important;
  }

  ul {
    display: flex;
    flex-flow: column;
  }
}

</style>