<template>
  <div>
    <div class="wrapper row align-items-center mt-4">
      <div class="col-sm-1">
        <v-btn class="text-left" plain @click.stop="$emit('carrinhoModal')">
          <v-icon class="appColor-primary remover-todos">mdi-alpha-x</v-icon>
        </v-btn>
      </div>
      <div class="col-sm-11">
        <h2 class="text-left">
          <span>{{ quantidadeCompra }}</span> ITENS ADICIONADOS
        </h2>
      </div>
    </div>
    <div class="lista-produtos px-8">
      <v-card
        class="p-2 my-6"
        v-for="(carrinhoProduto, i) in carrinhoProdutos"
        :key="i"
      >
        <carrinho-item
          :item="carrinhoProduto"
          @diminuirQuantidade="
            atualizarQuantidadeItem(carrinhoProduto, 'diminuir')
          "
          @aumentarQuantidade="atualizarQuantidadeItem(carrinhoProduto, 'soma')"
          @removerItem="removerItem(carrinhoProduto)"
        />
      </v-card>

      <div class="mt-16">
        <v-card class="p-2 CardTotal">
          <div class="row">
            <div class="col-sm-8">
              <h2 class="text-left font-weight-bold">Total</h2>
            </div>
            <div class="col-sm-4">
              <h2 class="text-right font-weight-bold appColor-primary">
                {{ totalCompra }}
              </h2>
            </div>
          </div>
        </v-card>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col-md-6 text-center text-md-left">
            <v-btn @click="comprar" class="appBG-primary appColor-white mt-2">
              Finalizar Compra
            </v-btn>
          </div>
          <div class="col-md-6 text-center text-md-right">
            <v-btn
              class="appColor-primary app-Border-primary mt-2"
              plain
              @click.stop="$emit('carrinhoModal')"
            >
              Continuar Comprando
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carrinhoItem from "@/components/carrinho_modal/carrinho_item.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";

export default {
  mixins: [CarrinhoMixin],
  components: {
    carrinhoItem,
  },
  data: () => ({
    carrinhoProdutos: [],
    totalCompra: 0,
    quantidadeCompra: 0,
  }),
  beforeMount() {
    this.atualizarCarrinhoCompra();
  },
  watch: {
    carrinhoProdutos: {
      handler: function (newcarrinho) {
        console.log(newcarrinho);
        this.atualizarTotalCompra(newcarrinho);
        this.atualizarquantidadeCompra(newcarrinho);
      },
      deep: true,
    },
  },
  methods: {
    atualizarQuantidadeItem(item, valor) {
      if (valor == "soma") {
        
        this.carrinhoProdutos.forEach((element) => {
          if (element.id == item.id) {
            element.quantidade = element.quantidade + 1;
          }
        });
      }
      if (valor == "diminuir") {
        this.carrinhoProdutos.forEach((element) => {
          if (element.id == item.id) {
            element.quantidade = element.quantidade - 1;
          }
        });
      }
      this.$_ACTIONS_carrinhoAtualizar(this.carrinhoProdutos);
    },

    comprar() {
      this.$router.push({ name: 'checkout' })
    },

    removerItem(item) {
      this.carrinhoProdutos = this.carrinhoProdutos.filter(
        (produto) => produto.id != item.id
      );
      this.$_ACTIONS_carrinhoAtualizar(this.carrinhoProdutos);
    },
    atualizarCarrinhoCompra() {
      this.carrinhoProdutos = this.$_GETTERS_carrinho;
    },
    atualizarTotalCompra(newcarrinho) {
      this.totalCompra = 0;
      for (let i = 0; i < newcarrinho.length; i++) {
        this.totalCompra += newcarrinho[i].quantidade;
      }
    },
    atualizarquantidadeCompra(newcarrinho) {
      this.quantidadeCompra = 0;
      for (let i = 0; i < newcarrinho.length; i++) {
        this.quantidadeCompra += newcarrinho[i].quantidade;
      }
    },
  },
};
</script>

<style scoped>
.remover-todos {
  font-size: 3rem;
}


@media (max-width: 600px) {

  .col-sm-1 {
    width: 10% !important;
    margin-right: 2em;  
  }

  .col-sm-11 {
    width: 80% !important;  
  }

}
</style>
