import HTTPService from '@/services/http_service.js'

export default class CategoriaService extends HTTPService {
    resource = 'categoria'
    cadastrar (responseFunctions, categoria) {
        this.request(responseFunctions, 'POST', this.resource, categoria)
    }

    // getExames (responseFunctions, nome) {
    //     let imprevisto = nome ?? ''
    //     if (imprevisto === '') {
    //         this.request(responseFunctions, 'GET', this.resource, null)
    //     } else {
    //         this.request(responseFunctions, 'GET', this.resource, null, nome)
    //     }
    // }

    getList (responseFunctions) {
      this.request(responseFunctions, 'GET', this.resource,null)
    }

    getAll (onSucess, onError, onEnd, page, per_page, filter, order, desc) {
      const responseFunctions = {
          onSucess,
          onError,
          onEnd
      }
      let queryparams ={
        page: page,
        per_page: per_page
      }
      if(filter ===''){
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }
      }else{
        let queryparams ={
          page: page,
          per_page: per_page,
          nome: filter,
        }
        if(order){
          let queryparams ={
            page: page,
            per_page: per_page,
            nome: filter,
            sort: desc?'nome':'-nome'
          }
          this.request(responseFunctions, 'GET', this.resource,null,queryparams)
        }else{
          this.request(responseFunctions, 'GET', this.resource, null, queryparams)
        }
      }
    }

    deleteCategoria ({ onSucess, onError, onEnd },  id ) {
      // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR. 
        const uri = `${this.resource}/delete/${id}`
        this.request({ onSucess, onError, onEnd }, 'POST', uri)
    }

    updateNomeCategoria ({ onSucess, onError, onEnd }, categoria) {
        const uri = `${this.resource}/${categoria.id}`
        this.request({ onSucess, onError, onEnd }, 'POST', uri, categoria)
    }

    // searchByNome (vm) {
    //     // Para usar esse método, o componente deve ter como atributos em data
    //     // "exames" (array) e "loading"(boolean)
    //     return nome => {
    //         if (!nome) {
    //             vm.exames = [];
    //             vm.exame='';
    //           }
    //           if (
    //             !nome ||
    //             !(nome = nome.trim()) ||
    //             nome.length < 3 ||
    //             vm.loading_exame
    //           ) {
    //             return;
    //           }
    //           vm.loading_exame = true;
    //           vm.exames = [];
    //           let queryParam = {
    //             nome: nome
    //           }
    //         //   const procedimentoService = new ProcedimentoService();
    //           const requestFunctions = {
    //             onSucess: (status) => (body) => {
    //               if (status === 200) {
    //                 vm.exames = body.data.exames;
    //               } else {
    //                 vm.exames = [];
    //               }
    //             },
    //             onError: (error) => {
    //               console.error(error);
    //               vm.exames = [];
    //             },
    //             onEnd: () => {
    //               vm.loading_exame = false;
    //             },
    //           };
    //           this.getExames(requestFunctions, queryParam );
    //     }
    // }
} 