<template>
  <div>
    <div>
      <header-geral />
    </div>

    <div class="p-4 mt-12">
      <div class="p-2 mt-2"></div>
    </div>

    <div class="w-75 container">
      <div class="d-flex px-8 justify-space-between">
        <h3 class="mb-5">MEUS PEDIDOS</h3>
        <v-btn @click="logout">Sair</v-btn>
      </div>

      <div class="container rounded-lg outlined">
        <div class="container appBG-primary row rounded-t-lg">
          <h4 class="white--text">Todos os pedidos</h4>
        </div>
        <div class="row container p-4 border">
          <div class="col-12" v-if="pedidos.length > 0">
            <v-card
              v-for="pedido in pedidos"
              :key="pedido.pedido_id"
              class="col-12 d-flex flex-column mb-4 rounded-t-lg"
              elevation="0"
              outlined
            >
              <div
                class="grey lighten-3 row rounded-t-lg grey--text"
                style="padding: 0"
              >
                <small class="col"
                  >Pedido <br />n° {{ pedido.carrinho_id | fillLeft(8) }}</small
                >
                <small class="col"
                  >Realizado no dia <br />{{
                    pedido.compra_data | formatData
                  }}</small
                >
                <small class="col"
                  >Valor total <br />R${{
                    parseFloat(pedido.preco)
                      | money({
                        prefix: "",
                        suffix: "",
                        thousands: ".",
                        decimal: ",",
                        precision: 2,
                      })
                  }}</small>

                  <small class="col"
                    >Status <br />{{
                      pedido.compra_status | statusPagamento
                    }}</small
                  >
                <small
                  @click="$router.push(`pedido/${pedido.carrinho_id}`)"
                  class="col-3 d-flex mt-2 appColor-primary detalhes-pedido"
                  >Exibir detalhes do pedido</small
                >
              </div>

              <v-card-text
                class="row"
                v-for="pacote in pedido.pacotes"
                :key="pacote.pacote_id"
              >
                <div class="col border card-img-wrapper">
                  <v-img
                    width="40"
                    class="mx-auto"
                    src="@/assets/images/minha-vacina.png"
                  ></v-img>
                </div>

                <div class="col">
                  <h4>x{{ pacote.quantidade }} {{ pacote.pacote_nome }}</h4>
                  <p>
                    {{
                      pacote.produtos
                        .reduce(
                          (acc, c) =>
                            acc + ` - ${c.quantidade}x ${c.produto_nome}`,
                          ""
                        )
                        .substr(2)
                    }}
                  </p>
                </div>
              </v-card-text>
              <div class="row">
                <v-divider></v-divider>
              </div>
              <div class="row p-2 d-flex align-content-right">
                <v-btn
                  @click="addToCartBatch(pedido)"
                  class="appBG-primary comprar-button white--text"
                  >Comprar novamente</v-btn
                >
              </div>
            </v-card>
          </div>
          <h3 class="grey--text" v-else>Nenhum pedido</h3>
        </div>
      </div>
    </div>

    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import VuexTokenMixin from "@/mixins/vuex_token_mixin.js";
import headerGeral from "@/components/template/header/header_geral.vue";
import footerApp from "@/components/template/footer_app.vue";
import CarrinhoMixin from "@/mixins/carrinho_mixin.js";
import CompraService from "@/services/compras_service";

export default {
  components: {
    headerGeral,
    footerApp,
  },

  mixins: [VuexUsuarioMixin, VuexTokenMixin, CarrinhoMixin],

  beforeMount() {
    if (!this.$store.state.token) {
      this.$router.push('/')
    }

    this.atualizarCarrinhoCompra();
  },

  watch: {
    carrinhoProdutos: {
      handler: function (newcarrinho) {
        console.log(newcarrinho);
        this.atualizarTotalCompra(newcarrinho);
        this.atualizarquantidadeCompra(newcarrinho);
      },
      deep: true,
    },
  },

  methods: {
    logout() {
      this.$_ACTIONS_setUsuario({});
      this.$_ACTIONS_setToken("");
      this.$router.replace("login");
    },

    addToCartBatch(pedido) {
      pedido.pacotes.map((p) => {
        let carrinho = {
          titulo: p.pacote_nome,
          // valorTotal: parseFloat(p.preco) * p.quantidade,
          valorUnitario: parseFloat(p.preco),
          quantidade: parseInt(p.quantidade),
          id: p.pacote_id,
        };

        this.$_ACTIONS_carrinho(carrinho)
      });
    },
  },

  filters: {
    statusPagamento(status) {
      const stts = {
          waiting: 'aguardando',
          paid: 'pago',
          declined: 'recusado'
      }

      return stts[status.toLowerCase()]
    },
    
    fillLeft: function (value = "", qtd = 0) {
      let v = value.toString();
      if (v.length < qtd) {
        return "0".repeat(qtd - v.length) + v;
      }

      return v;
    },

    formatData: function (data) {
      return data.substr(0, 10).split("-").reverse().join("/");
    },
  },

  mounted() {
    const compraService = new CompraService();
    compraService.getAll({
      onSucess: (status) => (body) => {
        if (status === 200) {
          this.pedidos = body.data.compras;
        }

        if (status === 400 || status === 500) {
          this.$router.push({ name: "login" });
        }
      },
      onError: () => {
      },
      onEnd: () => {
        this.loading = false;
      },
    });
  },

  // attachToCard(compra) {
  //   compra.pacotes.forEach(pacote => {
  //     //
  //   })
  // },

  data: () => ({
    pedidos: [],
    carrinhoProdutos: [],
    totalCompra: 0,
    quantidadeCompra: 0,
  }),
};
</script>


<style scoped>
small {
  text-align: left;
  font-weight: 600;
  color: #757575;
}

.card-img-wrapper {
  max-width: 128px;
  border-radius: 5px;
}

h4,
p {
  text-align: left;
}

.detalhes-pedido {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.comprar-button {
  text-transform: unset;
  font-weight: bold;
}
</style>