<template>
  <div>
    <v-item-group>
      <div class="botoes">
        <div v-for="(botao, i) in botoes" :key="i">
          <v-item v-slot="{  }">
            <v-btn
              class="px-2 py-5 my-1"
              :class="(filtro == botao.idCategoria) ? 'btn-border-primary' : 'btn-border-tranparent'"
              depressed
              width="100%"
              @click="clickBtn(botao)"
            >
              <div
                class="d-flex justify-content-start align-items-center w-100"
              >
                <v-img
                  class="mr-2"
                  contain
                  max-height="30px"
                  max-width="30px"
                  :src="(filtro == botao.idCategoria) ? botao.imgAtivo : botao.img"
                ></v-img>
                <p
                  class="font-weight-bold appColor-secondary btn-filtro"
                  :class="(filtro == botao.idCategoria) ? 'appColor-primary' : ''"
                >
                  {{ botao.textoBotao }}
                </p>
              </div>
            </v-btn>
          </v-item>
        </div>
      </div>
    </v-item-group>
  </div>
</template>

<script>
import CategoriaService from "../../../../services/categorias_service";

export default {

  props: {
    filtro: String
  }, 

  data: () => ({
    botoes: [],
    atual: '',
  }),

  beforeMount() {
    this.getCategorias();
  },

  methods: {
    clickBtn(botao) {

      if (botao.idCategoria == this.filtro) {
        this.$emit('mudaFiltroProduto', '')
      } else {
        this.$emit('mudaFiltroProduto', botao.idCategoria)
      }
      
    },
    getCategorias() {
      this.loading = true;
      const categoriaService = new CategoriaService();
      this.categorias = [];
    
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.categorias = body.data.categorias;
          this.botoes = body.data.categorias.map((categoria) => ({
            textoBotao: categoria.nome,
            imgAtivo: "",
            img: "",
            idCategoria: categoria.categoria_id,
          }));
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      categoriaService.getList({ onSucess, onError, onEnd });
    },
  },
};
</script>

<style scoped>
.btn-border-tranparent {
  border: 4px solid transparent;
  border-radius: 0;
}
.btn-border-primary {
  border: 4px solid #a92429;
  border-radius: 0;
}
.btn-filtro {
  font-size: 1rem;
  text-transform: none;
}
</style>