import HTTPService from '@/services/http_service.js'

export default class CEPService extends HTTPService {
  request ({ onSucess, onError, onEnd }, method, resource, bodyParams, queryParamsObj) {
      if (typeof onSucess !== 'function') {
          throw new Error('onSucess is not a function.')
      }
      if (typeof onError !== 'function') {
          throw new Error('onError is not a function.')
      }
      if (typeof onEnd !== 'function') {
          throw new Error('onEnd is not a function.')
      }
      const headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");
      
      let url = ''
      if (method === 'GET') {
          const queryParams = queryParamsObj ? this.encodeQueryParams(queryParamsObj) : ''
          url = this.baseURL(resource + queryParams)
      } else {
          url = this.baseURL(resource)
      }
      const redirect = 'follow'
      const config =  { method , redirect}
      if (['POST', 'GET', 'DELETE'].includes(method)) {
          config.headers = headers
      }
      if (method === 'POST' && typeof bodyParams === 'object' && JSON.stringify(bodyParams) !== '{}') {
          const urlencoded = new URLSearchParams();
          Object.keys(bodyParams).forEach(campo => {
              if (bodyParams[campo] instanceof Object) {
                  const objParam = bodyParams[campo]
                  Object.keys(objParam).forEach(nameParam => {
                      urlencoded.append(`${campo}[${nameParam}]`, objParam[nameParam])    
                  })
              } else {
                  urlencoded.append(campo, bodyParams[campo])
              }
                  
          })
          config.body = urlencoded 
      }
      let request = null
      try {
          request = fetch(url, config)
      } catch (error) {
          request = new Error(error)
      }
      if (request instanceof Error) {
          onError()
      } else {
          request.then(response => {
              const { status } = response
              // if (status === 403) {
              //     this.tratarErroForbidden();
              //     return;
              // }
              response.json()
                  .then(onSucess ? onSucess(status) : () => {})
                  .catch(onError || (() => {}))
                  .finally(onEnd || (() => {}))
          })
          .catch(onError || (() => {}))
          .finally(onEnd || (() => {}))
      }
  }

    baseURL (args) {
        return `http://viacep.com.br/ws/${args}`      
    }

    buscaCep (responseFunctions, cep) {
      this.request(responseFunctions, 'GET', `${cep}/json`, null)
    }

} 