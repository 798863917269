import { mapActions, mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(
            {
                $_GETTERS_usuario: 'getUsuario',
                $_GETTERS_token: 'getToken',
                $_GETTER_snackbar: 'getSnackbar',
                $_GETTER_perfil: 'getPerfil',
            }
        ),
    },
    methods: {
        ...mapActions({
            $_ACTIONS_setUsuario: 'setUsuario',
            $_ACTIONS_setToken: 'setToken',
            $_ACTIONS_perfil: 'setPerfil',
            $_ACTIONS_showSnackbarMessage: 'showSnackbarMessage',
        }),
    }
}