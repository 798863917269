<template>
  <div>
    <div>
      <header-geral />
    </div>
    <div class="margintop-menu mb-8">
      <div class="container-fluid h-100">
        <div class="row justify-content-center align-items-center h-100">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <v-card elevation="8">
              <div class="card-body p-4">
                <div class="pt-2 form-login">
                  <div class="form-group">
                    <h2 class="appColor-primary">Criar minha conta</h2>
                    <p v-if="loginCompra" class="h5">Crie sua conta para continuar comprando</p>
                    <p>Informe os dados para criar-la.</p>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <v-text-field
                          color="black"
                          v-model="nome"
                          type="'text'"
                          label="Nome Completo"
                          placeholder="Digite seu nome completo"
                          id="NomeCompleto"
                          required
                          clearable
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mb-3">
                        <v-text-field
                          color="black"
                          v-mask="'###.###.###-##'"
                          v-model="cpf"
                          :type="'text'"
                          label="Digite seu CPF"
                          id="CPF"
                          placeholder="000.000.000-00"
                          clearable
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mb-3">
                        <v-text-field
                          color="black"
                          v-mask="'(##) #####-####'"
                          v-model="telefone"
                          :type="'text'"
                          label="Digite seu telefone"
                          id="Telefone"
                          placeholder="(99) 99999-9999"
                          clearable
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <v-text-field
                          color="black"
                          v-model="email"
                          type="email"
                          label="Email"
                          placeholder="Digite seu email"
                          id="EmailUsuario"
                          required
                          clearable
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <v-text-field
                          color="black"
                          v-model="senha"
                          :append-icon="
                            mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          :type="mostrarSenha ? 'text' : 'password'"
                          label="Senha"
                          id="SenhaUsuario"
                          placeholder="Digite sua senha"
                          @click:append="mostrarSenha = !mostrarSenha"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-3">
                        <v-text-field
                          color="black"
                          v-model="nascimento"
                          :type="'date'"
                          label=""
                          id="nascimentoUsuario"
                          placeholder="Insira sua data de nascimento"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="text-right">
                        <template>
                          <v-container fluid>
                            <v-checkbox
                              class="termo-politica"
                              color="appColor-primary"
                              v-model="termoPolitica"
                            >
                              <template v-slot:label>
                                <div>
                                  Estou ciente e CONCORDO com os
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <a
                                        class="links"
                                        target="_blank"
                                        href=""
                                        @click.stop
                                        v-on="on"
                                      >
                                        termos de aceitação
                                      </a>
                                    </template>
                                    Abrir Termo em outra página
                                  </v-tooltip>
                                  e
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <a
                                        class="links"
                                        target="_blank"
                                        href=""
                                        @click.stop
                                        v-on="on"
                                      >
                                        políticas de privacidade
                                      </a>
                                    </template>
                                    Abrir Politicas de privacidade em outra
                                    página
                                  </v-tooltip>
                                  da São Carlos Vacinas
                                </div>
                              </template>
                            </v-checkbox>
                          </v-container>
                        </template>
                      </div>
                    </div>
                  </div>

                    <v-alert
                      v-for="erro in Object.keys(erros)"
                      :key="erro"
                      dense
                      outlined
                      type="error"
                    >
                      <strong>{{ erro }}: </strong> {{ erros[erro] }}
                    </v-alert>


                  <div class="form-group mb-0 text-center">
                    <v-btn
                      class="appBG-primary px-8"
                      :disabled="!termoPolitica"
                      @click.prevent="criarConta"
                    >
                      <span class="appColor-white" v-if="!loading">
                        Criar conta
                      </span>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </v-btn>
                  </div>
                  <div class="form-group mt-8 mb-0 text-center">
                    <v-btn text @click.prevent="voltarLogin">
                      <span class="appColor-primary">
                        Voltar para a tela de login
                      </span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div>
      <footer-app />
    </div>
  </div>
</template>

<script>
import headerGeral from "@/components/template/header/header_geral.vue";
import Mixin from "@/mixins/vuex_mixin.js";
import UsuarioService from "@/services/usuario_service.js";
import footerApp from "@/components/template/footer_app.vue";

export default {
  mixins: [Mixin],
  components: {
    headerGeral,
    footerApp,
  },
  data: () => ({
    nascimento: "",
    nome: "",
    cpf: "",
    telefone: "",
    email: "",
    senha: "",
    termoPolitica: false,
    loading: false,
    mostrarSenha: false,
    erros: [],
    loginCompra: false
  }),

  beforeMount() {
    if (this.$store.state.token) {
      this.$router.push('/')
    }

    if (this.$route.params.loginCompra) {
      this.loginCompra = true
    }
  },
  
  methods: {
    criarConta() {
      const usuario = {
        nome: this.nome,
        email: this.email,
        cpf: this.cpf,
        telefone_1: this.telefone,
        senha: this.senha,
        data_nascimento: this.nascimento.split('/').reverse().join('-'),
      };
      const usuarioService = new UsuarioService();
      this.loading = true;
      usuarioService.register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Usuario criado com sucesso!",
                color: "sucess",
              });
              
              if (this.loginCompra) {
                this.$router.push({name: "login", params: {loginCompra: this.loginCompra}});
              } else {
                this.$router.replace("principal");
              }
              
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
              this.erros = body.errors

            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        usuario
      );
    },
    voltarLogin() {
      this.$router.replace("login");
    },
  },
};
</script>