<template>
  <div>
    <div>
      <header-geral />
    </div>
    <div class="margintop-menu mb-8">
      <div class="container-fluid h-100">
        <div class="row justify-content-center align-items-center h-100">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <v-card elevation="8">
              <div class="card-body p-4">
                <div class="pt-2 form-login">
                  <div class="form-group">
                    <h2 class="appColor-primary">Já tem uma conta?</h2>
                    <p v-if="loginCompra" class="h5">Faça login para continuar a compra</p>
                    <p>Informe os dados para acessá-la.</p>
                  </div>
                  <div class="form-group mb-3">
                    <v-text-field
                      color="black"
                      v-model="email"
                      type="email"
                      label="Email"
                      placeholder="Digite seu email"
                      id="EmailUsuario"
                      required
                      clearable
                    ></v-text-field>
                  </div>

                  <div class="form-group mb-3">
                    <v-text-field
                      color="black"
                      v-model="senha"
                      :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="mostrarSenha ? 'text' : 'password'"
                      label="Senha"
                      id="SenhaUsuario"
                      placeholder="Digite sua senha"
                      @click:append="mostrarSenha = !mostrarSenha"
                    ></v-text-field>
                  </div>
                  <div class="text-right">
                    <a class="text-muted" @click="esqueceu_senha"
                      ><small>Esqueceu sua senha?</small></a
                    >
                  </div>

                  <div class="form-group mb-0 text-center">
                    <v-btn class="appBG-primary px-8" @click.prevent="login">
                      <span class="appColor-white" v-if="!loading">
                        Entrar
                      </span>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </v-btn>
                  </div>
                  <div class="form-group mt-8 mb-0 text-center">
                    <p class="font-weight-bold mb-6">Ainda não possui conta? <br/>Realize seu cadastro agora.</p>
                    <v-btn class="appBG-primary px-8" @click.prevent="criarConta">
                      <span class="appColor-white">
                        Criar Conta
                      </span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div>
      <footer-app />
    </div>
  </div>
</template>

<script>
import headerGeral from "@/components/template/header/header_geral.vue";
import Mixin from "@/mixins/vuex_mixin.js";
import AuthService from "@/services/auth_service";
import footerApp from "@/components/template/footer_app.vue"

export default {
  mixins: [Mixin],
  components: {
    headerGeral,
    footerApp
  },
  data: () => ({
    email: "",
    senha: "",
    loading: false,
    mostrarSenha: false,
    loginCompra: false,
  }),
  
  beforeMount() {
    if (this.$store.state.token) {
      this.$router.push('/')
    }

    if (this.$route.params.loginCompra) {
      this.loginCompra = true
    }
  },

  methods: {
    esqueceu_senha() {
      this.$router.replace("esqueceu-senha");
    },
    criarConta() {
      this.$router.push({name: "nova-conta", params: {loginCompra: this.loginCompra}})
    },
    login() {
      //  if(this.lembrar){
      //     console.log("login")
      //     let pass = {email: this.email, senha: this.senha}
      //     this.setRemember(pass);
      //   }
      this.loading = true;
      const usuario = {
        email: this.email,
        senha: this.senha,
      };
      const authService = new AuthService();
      authService.login(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_setToken(body.data.token);
              this.$_ACTIONS_setUsuario({id: body.data.id_usuario});
              // this.DADOS_CADASTRAIS_MIXIN_getDadosCadastrais();
              if (this.loginCompra) {
                this.$router.replace({name: "checkout"});
              } else {
                this.$router.replace("dashboard");
              }
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        usuario, 
        this.$_GETTER_perfil.description
      );
    },
  },
};
</script>