<template>
  <div>
    <v-footer class="footerBG pt-14 pb-16" padless>
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="logofooter">
              <router-link to="/">
                <v-img
                  max-width="295px"
                  src="@/assets/images/logo-saocarlos.png"
                ></v-img>
              </router-link>
            </div>
            <p class="text-left mt-3">
              A São Carlos Vacinas nasceu com a missão de ofertar vacinas para
              crianças, adolescentes, adultos e idosos com vistas a promover
              saúde e qualidade de vida para todos.
            </p>
          </div>
          <div class="col-lg-3">
            <h3 class="text-uppercase appColor-primary text-left">Informações</h3>
            <ul class="itens-footer">
              <li>
                <a href="/#FiltroGeral">Vacinas</a>
              </li>
              <li>
                <a
                  href="https://saocarlosmedicinaesaude.com.br/"
                  target="_blank"
                  >Grupo São Carlos</a
                >
              </li>
              <li>
                <router-link to="/quem-somos">Quem somos</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-5">
            <h3 class="text-uppercase appColor-primary text-left">Contatos</h3>
            <div class="row">
              <div class="col-lg-5">
                <ul class="itens-footer">
                  <li>
                    <div class="d-flex align-items-center">
                      <v-icon class="appColor-primary mr-2">mdi-phone</v-icon>
                      <h4 class="text-dark">Telefone</h4>
                    </div>
                    <p class="pl-8 mb-3">
                      <a href="tel:5585991242654">
                        (85) 99124-2654
                      </a>
                    </p>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <v-icon class="appColor-primary mr-2">mdi-email-outline</v-icon>
                      <h4 class="text-dark">Email</h4>
                    </div>
                    <p class="pl-8 mb-3">
                      <a href="mailto:vacinas@saocarlosimagem.com.br">
                        vacinas@saocarlosimagem.com.br
                      </a>
                    </p>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <v-icon class="appColor-primary mr-2">mdi-whatsapp</v-icon>
                      <h4 class="text-dark">Whatsapp</h4>
                    </div>
                    <p class="pl-8 mb-3">
                      <a href="https://api.whatsapp.com/send?phone=5585 99124-2654&text=Olá, gostaria de informações sobre a vacina"  target="_blank">
                        85 99124-2654
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
              <div class="col-lg-7">
                <ul class="itens-footer">
                  <li>
                    <div class="d-flex align-items-center">
                      <v-icon class="appColor-primary mr-2">mdi-google-maps</v-icon>
                      <h4 class="text-dark">Endereços</h4>
                    </div>
                    
                    <p class="pl-8 mb-3">
                      <b class="text-dark">{{ unidades[unidade_atual].nome_fantasia.toUpperCase() }}</b> - {{ unidades[unidade_atual].logradouro }}, {{ unidades[unidade_atual].numero }} - {{ unidades[unidade_atual].bairro }}, {{ unidades[unidade_atual].cidade }} {{ unidades[unidade_atual].referencia }}
                    </p>
                    <!-- <p class="pl-8 mb-3">
                      <b class="text-dark">UNIDADE PÁTIO DOM LUIS</b> - Av. Dom
                      Luís, 1200 - Meireles, Fortaleza Loja 143 e 144 Térreo
                    </p>
                    <p class="pl-8 mb-3">
                      <b class="text-dark">UNIDADE SUL</b> - Rua Mario Alencar
                      Araripe, 48 - Edson Queiroz
                    </p>
                    <p class="pl-8 mb-3">
                      <b class="text-dark">UNIDADE SÃO CARLOS IMAGEM ANEXO</b> - Rua
                      Otoni Façanha de Sá, 69 - Sala 201 - 2º Andar
                    </p> -->
                  </li>
                </ul>
                <a href="https://api.whatsapp.com/send?phone=5585 99124-2654&text=Olá, gostaria de informações sobre a vacina"  target="_blank">
                  <img class="wppimg" src="@/assets/images/gif-wpp.gif">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-footer>
    <div class="subfooter appBG-primary d-flex justify-content-center">
      <a href="https://prolins.com.br" target="_blank">
        <v-img
          src="@/assets/images/logo-prolins-branco.png"
          max-width="140px"
        ></v-img>
      </a>
    </div>
  </div>
</template>

<script>

import UnidadeService from "../../services/unidades_service";

export default {

  data: () => ({
    unidades: [],
    unidade_atual: 0,
  }),

  beforeMount() {
    this.getUnidades();

    setInterval(() => {
      if (this.unidade_atual+1==this.unidades.length) {
        this.unidade_atual = 0
      } else {
        this.unidade_atual++
      }
    }, 1500)
  },

  methods: {
    getUnidades() {
      this.loading = true
      const unidadeService = new UnidadeService()
      this.unidades=[];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.unidades = body.data.unidades

        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };

      unidadeService.getList({onSucess, onError, onEnd});
    }
    
  }
}
</script>

<style scoped>
.wppimg{
  width: 5%;
    position: fixed;
    right: 11px;
    bottom: 11px;
}
.subfooter {
  padding: 2rem 0;
}

.itens-footer {
  list-style: none;
  padding-left: 0;
}

.itens-footer li {
  text-align: left;
}

.itens-footer li a {
  color: #000;
}
</style>