<template>
  <div>
    <filtro-galeria :key="filtro" :filtro="filtro" />
  </div>
</template>

<script>
import filtroGaleria from "@/components/home/filtro_geral/tabs_components/filtro_galeria.vue";

export default {
  props: {
    media: String,
    filtro: String,
  },
  
  components: {
    filtroGaleria,
  },

};
</script>