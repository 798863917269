import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import { global } from '@/config/constants.js'
import router from './router'
import VueMask from 'v-mask'
import money from 'v-money-plus'

Vue.use(VueMask);
Vue.use(money, {precision: 2})

Vue.config.productionTip = false
Vue.prototype.$global = global

var filterTruncate = function(text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
};

var isMobile = function () {
    alert()
}

Vue.prototype.$functions = isMobile;

Vue.filter('truncate', filterTruncate);


new Vue({
    store,
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')