import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { global } from '@/config/constants'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        carrinho: [],
        usuario: {},
        snackbar: {
            message: '',
            color: ''
        },
        data: {},
        token: '',
        perfil: { id: 0, description: 'home' },
        filtroLoja: '',
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    getters: {
        getFiltroLoja: state => {
            return state.filtroLoja
        },
        getUsuario: state => {
            return state.usuario
        },
        getSnackbar: state => {
            return state.snackbar
        },
        getToken: state => {
            return state.token
        },
        getPerfil: state => {
            return state.perfil
        },
        getData: state => {
            return state.data
        },
        getCarrinho: state => {
            return state.carrinho
        },
    },
    mutations: {
        setUsuario: (state, usuario) => {
            state.usuario = usuario
        },
        setFiltroLoja: (state, filtro) => {
            state.usuario = filtro
        },
        setSnackbar: (state, snackbar) => {
            state.snackbar = snackbar
        },
        setToken: (state, token) => {
            state.token = token
        },
        setPerfil: (state, perfil) => {
            state.perfil = perfil
        },
        setData: (state, data) => {
            state.data = data
        },
        setCarrinho: (state, item) => {
            let existe = 0
            if (state.carrinho.length > 0) {
                state.carrinho.forEach(element => {
                    if (element.id == item.id) {
                        existe = 1
                        console.log("vou somar a quantidades ....")
                        element.quantidade = element.quantidade + item.quantidade
                    }
                });
                if (existe == 0) {
                    state.carrinho.push(item)
                }
                console.log("não vou adicionar")
            } else {
                state.carrinho.push(item)
                console.log("adicionei um item")
            }
            console.log(state.carrinho.length)
        },
        setCarrinhoAtualizar: (state, novo) => {
            state.carrinho = novo
        },
    },
    actions: {
        setUsuario({ commit }, usuario) {
            commit('setUsuario', usuario)
        },
        setToken({ commit }, token) {
            commit('setToken', token)
        },
        setPerfil({ commit }, perfil) {
            commit('setPerfil', perfil)
        },
        setData({ commit }, data) {
            commit('setData', data)
        },
        setCarrinho({ commit }, item) {
            commit('setCarrinho', item)
        },
        setCarrinhoAtualizar({ commit }, novo) {
            commit('setCarrinhoAtualizar', novo)
        },
        showSnackbarMessage({ commit }, { message, color }) {
            const snackbar = { message, color }
            const snackbarStateIsValid = color === '' ?
                true :
                Object.keys(global.snackbar.colors).includes(color)
            if (!snackbarStateIsValid) {
                throw new Error('Invalid state provided to snackbar.')
            } else {
                commit('setSnackbar', snackbar)
            }
        }
    },
    modules: {}
})