<template>
  <div>
    <div
      class="bannerPacotes-BG"
      :style="'background: url(' + definirBG() + ')'"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="pl-3" style="border-left: 3px solid #ffcc06">
              <h2 class="text-left banner-h2">Conheça nossos Pacotes de</h2>
              <h1 class="appColor-primary text-left banner-h1">
                Vacinação para toda a família!
              </h1>
            </div>
            <div class="text-left mt-4">
              <v-btn class="appBG-primary appColor-white mt-2">
                <v-icon class="app-font-size-icon" left>
                  mdi-checkbox-blank-circle
                </v-icon>
                Ver ofertas
              </v-btn>
            </div>
          </div>
          <div class="col-lg-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fotoBanner1 from "@/assets/images/banner-pacotes.png";
import fotoBanner2 from "@/assets/images/exemplo.png"
import fotoBanner3 from "@/assets/images/exemplo.png"
import fotoBanner4 from "@/assets/images/exemplo.png"

export default {
  data: () => ({}),
  methods: {
    definirBG() {
      let style = "";
      if (window.matchMedia("(min-width: 1264px)").matches) {
        style = fotoBanner1;
      }
      if (window.matchMedia("(min-width: 960px) and (max-width: 1263px)").matches) {
        style = fotoBanner2;
      }
      if (window.matchMedia("(min-width: 600px) and (max-width: 959px)").matches) {
        style = fotoBanner3;
      }
      if (window.matchMedia("(max-width: 599px)").matches) {
        style = fotoBanner4;
      }
      return style;
    },
  },
};
</script>

<style scoped>
.bannerPacotes-BG {
  padding: 3rem 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.banner-h1 {
  font-size: 3.4rem;
  font-weight: 800;
}
.banner-h2 {
  font-size: 2.8rem;
  font-weight: 600;
}


@media (max-width: 600px)
{
  h2 {
    font-size: 30px !important;
  }

  h1 {
    font-size: 34px !important;
  }
}
</style>