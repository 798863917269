import HTTPService from '@/services/http_service.js'

export default class AgendaService extends HTTPService {
    resource = 'horario'
    getAll(responseFunctions, paginationComponent = null, filtro = {}) {
        const pagination = paginationComponent
        this.request(
            responseFunctions,
            'GET',
            this.resource,
            null,
            { ...pagination, ...filtro }
        )
    }
    getAllAgenda(responseFunctions, paginationComponent = null, filtro = {}) {
        if (paginationComponent === null) {
            this.request(
                responseFunctions,
                'GET',
                this.resource,
                null,
                null
            )
        }
        const pagination = paginationComponent
        this.request(
            responseFunctions,
            'GET',
            this.resource,
            null,
            { ...pagination, ...filtro }
        )
    }
    getAgendaList(responseFunctions, idMedico, options) {
        this.request(responseFunctions, 'GET', this.resource + '/medico/' + idMedico, null, options)
    }

    agendar(responseFunctions, request) {
        const uri = 'agendamento'
        this.request(responseFunctions, 'POST', uri, request)
    }

    agendar_paciente(responseFunctions, request) {
        const uri = 'agendamento/cliente'
        this.request(responseFunctions, 'POST', uri, request)
    }

    criarHorario(responseFunctions, req) {
        this.request(responseFunctions, 'POST', this.resource, req)
    }

    criarAgenda(responseFunctions, req, multipla) {
        if (multipla) {
            this.jsonRequest(responseFunctions, 'POST', 'horario-multiple', req)
        } else {
            this.jsonRequest(responseFunctions, 'POST', this.resource, req)
        }
    }

    criarAgendaMultiplo(responseFunctions, params) {
        this.request(responseFunctions, 'POST', this.resource + '/multiplos', params)
    }

    atualizarTipoProcedimentoDeAgenda(responseFunctions, idTipoProcedimento, agendaID) {
        const url = `${this.resource}/${agendaID}`
        this.request(responseFunctions, 'POST', url, {
            id_tipo_procedimento: idTipoProcedimento,
            alterado_por: 1
        })
    }

    excluirAgenda(responseFunctions, agendaID) {
        // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR. 
        const url = `${this.resource}/delete/${agendaID}`
        this.request(responseFunctions, 'POST', url)
    }
}