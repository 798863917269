<template>
  <div class="home">
    <v-navigation-drawer v-model="abaFiltroMobile" fixed temporary width="60%">
      <botoes-filtro-mobile class="px-2 py-3" :filtro="filtro" @mudaFiltroProduto="mudaFiltroProdutos"/>
    </v-navigation-drawer>
    <div>
      <header-geral />
    </div>
    <div class="banner-principal">
      <v-img src="@/assets/images/banner.jpg"></v-img>
    </div>
    <div class="mb-5">
      <barra-flutuante />
    </div>
    <div class="mb-5">
      <produtos-mais-procurados />
    </div>
    <div class="mb-5" id="banner">
      <banner-pacotes />
    </div>
    <div>
      <filtro-geral id="FiltroGeral" :key="filtro" v-bind:filtro="getFiltro" @mudaFiltroProduto="mudaFiltroProdutos" @abaFiltro="abaFiltroMobile = !abaFiltroMobile" />
    </div>
    <div class="mt-16">
      <calendario-vacinal />
    </div>
    <div class="mt-16">
      <servicos-especiais />
    </div>
    <div class="mt-16">
      <depoimentos />
    </div>
    <div class="mt-16">
      <painel-redes-sociais />
    </div>
    <div class="mt-16">
      <footer-app />
    </div>
  </div>
</template>

<script>
import headerGeral from "@/components/template/header/header_geral.vue"
import barraFlutuante from "@/components/template/barra_flutuante.vue";
import produtosMaisProcurados from "@/components/home/produtos_mais_procurados.vue";
import bannerPacotes from "@/components/home/banner_pacotes.vue";
import filtroGeral from "@/components/home/filtro_geral/index.vue";
import botoesFiltroMobile from "@/components/home/filtro_geral/tabs_components/botoes_filtro_mobile.vue";
import calendarioVacinal from "@/components/home/calendario_vacinal/index.vue";
import servicosEspeciais from "@/components/home/servicos_especiais.vue";
import depoimentos from "@/components/home/depoimentos.vue"
import painelRedesSociais from "@/components/home/painel_redes_sociais.vue"
import footerApp from "@/components/template/footer_app.vue"

export default {
  components: {
    headerGeral,
    barraFlutuante,
    produtosMaisProcurados,
    bannerPacotes,
    filtroGeral,
    botoesFiltroMobile,
    calendarioVacinal,
    servicosEspeciais,
    depoimentos,
    painelRedesSociais,
    footerApp
  },
  methods: {
    mudaFiltroProdutos(id) {
      this.filtro = id;
    }
  },
  data: () => ({
    abaFiltroMobile: null,
    dialog: false,
    filtro: '',
  }),
  computed: {
    getFiltro() {
      return this.filtro
    }
  }
};
</script>

<style scoped>
.banner-principal {
  margin-top: 8rem;
}
</style>
