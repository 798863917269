<template>
  <div id="app">
    <v-app>
      <!-- <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div> -->
      <router-view />
      <div>
        <snackbar />
      </div>
    </v-app>
  </div>
</template>

<script>
import Snackbar from "@/components/widgets/snackbar.vue";

export default {
  components: {
    Snackbar,
  },
};
</script>

<style >
/* App css */
@import "assets/css/bootstrap.min.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* #nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
} */

.appColor-primary,
.appColor-primary--text {
  color: #a92429 !important;
}

.appColor-secondary {
  color: #959595;
}

.appColor-white {
  color: #fff !important;
}

.footerColor {
  color: #dcdcdc !important;
}

.appBG-primary {
  background-color: #a92429 !important;
}

.appBG-secondary {
  background-color: #959595;
}

.appBG-white {
  background-color: #fff !important;
}

.footerBG {
  background-color: #dcdcdc !important;
}

.app-Border-primary {
  border: 1px #a92429 solid;
}

.app-font-size-icon {
  font-size: 0.7rem !important;
}

.margintop-menu {
  margin-top: 10rem;
}

p {
  margin-bottom: 0 !important;
}

#tabs-calendarioVacinal .v-tabs-bar,
#tabs-calendarioVacinal .v-tabs-slider.transparent {
  background-color: transparent !important;
}

.termo-politica label {
  margin-bottom: 0;
}

.termo-politica .links {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.6);
}
.cancel{
  text-align: justify;
  line-height: 18px;
  margin-top: 17px;
}
.cardresum{
  margin-top: -11rem;
}
@media (max-width: 600px)
{
  .banner-principal {
    height: fit-content !important;
    margin-top: 0px !important;
  }

  .app-menugeral .v-toolbar__content {
    height: 150px !important;
  }

  .margintop-menu {
    margin-top: 3em;
  }
}


</style>
