<template>
  <div>
    <v-container>
      <div class="d-flex mb-12">
        <h2 class="text-left mr-2">Veja também</h2>
        <div class="pl-3" style="border-left: 3px solid #a92429">
          <p class="appColor-secondary font-weight-bold text-left">
            Clientes que compraram este<br />
            item também compraram.
          </p>
        </div>
      </div>
      <div class="galeria-mais-procurado">
        <div class="row">
          <div class="col-lg-3" v-for="(produto, i) in produtos" :key="i">
            <v-img
              :src="produto.src"
              class="text-center"
              aspect-ratio="2"
              contain
            ></v-img>
            <h4 class="text-center mb-0" style="height:50px">{{ produto.titulo | truncate(25, "...") }}</h4>
            <p style="height:50px" class="text-center appColor-secondary mb-2">
              {{ produto.descricao | truncate(25, "...") }}
            </p>

            <h3 class="my-1 text-center font-weight-bold appColor-primary">
              R$
              {{
                produto.valorAtual
                  | money({
                    prefix: "",
                    suffix: "",
                    thousands: ".",
                    decimal: ",",
                    precision: 2,
                  })
              }}
            </h3>
            <p class="font-weight-bold mb-0" v-if="produto.parcelado">
              ou {{ produto.quantidade_parcelas }}x de R${{
                produto.preco_parcelado
              }}
            </p>
            <v-btn
              class="appBG-primary appColor-white" :href="produto.href"
              @click="detalhesProduto(produto.produtoId)"
            >
              <v-icon class="app-font-size-icon" left>
                mdi-checkbox-blank-circle
              </v-icon>
              Ver mais
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import LojaService from "@/services/loja_service";

export default {
  mounted() {
    this.getProdutos()
  },
  data: () => ({
    produtos: []
  }),
  methods: {
    getProdutos() {
      this.loading = true;
      const lojaService = new LojaService();
      this.produtos = [];
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.produtos = body.data.loja.map((pack) => ({
            src: pack.foto?? require("@/assets/images/minha-vacina.png"),
            titulo: pack.nome,
            // junta o titulo dos produtos
            descricao: pack.produtos.reduce(
              (acc, p) => acc + ` - ${p.produto}`,
              ""
            ),
            promocao: true,
            // valorAnterior: "R$ 350",
            valorAtual: pack.preco * 1,
            parcelado: false,
            quantidade_parcelas: "",
            // preco_parcelado: "",
            href: `/detalhes-produto/${pack.pacote_id}`,
          }));
          this.produtos = this.produtos.slice(0,4)
        } else {
          // console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {
        this.loading = false;
      };
      lojaService.getListMostWanted({ onSucess, onError, onEnd });
    },
    detalhesProduto(produtoId) {
      this.$router.replace({ name: 'detalhes-produto', params: { produtoId: produtoId } });
    }
  }
};
</script>